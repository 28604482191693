import { useAccount, useConfig } from "wagmi";
import PresaleABI from "./presaleAbi.json";
import TokenABI from "./tokenAbi.json";

export const PresaleAddress = () => {
  const { chains } = useConfig();
  // console.log(' --------------');
  // console.log('chains:', chains);
  // console.log(' --------------');
  const { chain } = useAccount();
  const presaleContract = {
    [chains[0]?.id]: "0xAd52f33f9Fc4DE396f2e8236438e9c590680B54B",
    [chains[1]?.id]: "0x5a22463D758E0FAD860845ad23524FF12dfaB15f",
  };

  if (chains.find((chains) => chains?.id === chain?.id))
    return presaleContract[chain?.id];
  else return "0xAd52f33f9Fc4DE396f2e8236438e9c590680B54B";
};

export const TokenAddressUSDT = () => {
  const { chains } = useConfig();
  const { chain } = useAccount();

  const tokenContract = {
    [chains[0]?.id]: "0x55d398326f99059fF775485246999027B3197955",
    [chains[1]?.id]: "0xdAC17F958D2ee523a2206206994597C13D831ec7",
  };
  if (chains.find((chains) => chains?.id === chain?.id))
    return tokenContract[chain?.id];
  else return "0x55d398326f99059fF775485246999027B3197955";
};
export const TokenAddressSPLA = () => {
  const { chains } = useConfig();
  const { chain } = useAccount();

  const tokenContract = {
    [chains[0]?.id]: "0xA4b3445A58111ABD407C34402aB59B0FE05bFF5a",
    [chains[1]?.id]: "0x1c5440eD1cCE47cB83EA5533FC87a3f5237dd08E",
  };
  if (chains.find((chains) => chains?.id === chain?.id))
    return tokenContract[chain?.id];
  else return "0xA4b3445A58111ABD407C34402aB59B0FE05bFF5a";
};
export const JsonRpc = () => {
  const { chains } = useConfig();
  const { chain } = useAccount();

  const jsonRPC = {
    [chains[0]?.id]: "https://bsc-dataseed1.binance.org/",
    [chains[1]?.id]:
      "https://mainnet.infura.io/v3/90851edebc9741589f9843fff2175793",
  };
  if (chains.find((chains) => chains?.id === chain?.id))
    return jsonRPC[chain?.id];
  else return "https://bsc-dataseed1.binance.org/";
};
export const PresaleAbi = () => {
  const { chains } = useConfig();
  const { chain } = useAccount();

  const presaleAbi = {
    [chains[0]?.id]: PresaleABI,
    [chains[1]?.id]: PresaleABI,
  };
  if (chains.find((chains) => chains?.id === chain?.id))
    return presaleAbi[chain?.id];
  else return PresaleABI;
};

export const TokenAbi = () => {
  const { chains } = useConfig();
  const { chain } = useAccount();

  const tokenAbi = {
    [chains[0]?.id]: TokenABI,
    [chains[1]?.id]: TokenABI,
  };
  if (chains.find((chains) => chains?.id === chain?.id))
    return tokenAbi[chain?.id];
  else return TokenABI;
};
