export default {
  ABOUT: 'perkenalan',
  TOKENOMICS: 'Tokenomics',
  STAKING: 'mempertaruhkan',
  'HOW TO BUY': 'Cara membeli',
  ROADMAP: 'peta jalan',
  FAQ: 'Pertanyaan yang Sering Diajukan',
  'BUY NOW': 'beli sekarang',
  'ELONXCAT is the real': 'ELONXCAT itu nyata',
  ELONMUSK: 'Elon Musk',
  'SpaceX and X': 'SpaceX dan',
  'meme project': 'proyek meme',
  'Advanced Web3 Ecosystem': 'Ekosistem Web3 Tingkat Lanjut',
  'Potential of project': 'Potensi Proyek',
  'Market cap Target': 'kapitalisasi pasar sasaran',
  Investors: 'investor',
  'WHITE PAPER': 'buku putih',
  AUDIT: 'terima kasih',
  'Presale will start soon. Join the Presale':
    'Pra-penjualan akan segera dimulai. Ikut serta dalam pra-penjualan',
  'TOKEN DISTRIBUTION DATE': 'Tanggal Distribusi Token',
  Raised: 'Jumlah yang terkumpul',
  'ELONXCAT sold': 'Volume penjualan ELONXCAT',
  BUY: 'pembelian',
  'MULTI BRIDGE UTILITY': 'Utilitas Beberapa Jembatan',
  'Total Supply': 'pasokan total',
  'ELONXCAT ACHIEVEMENTS': 'Performa ELONXCAT',
  'Ecosystem Percentage': 'rasio ekosistem',
  'NFT MARKET PLACE': 'Pasar NFT',
  'Unique collections': 'koleksi unik',
  'Launch at the same time as the project': 'Dirilis bersamaan dengan proyek',
  'NFT Box and Airdrop': 'Kotak NFT dan airdrop',
  'DISCOVER NFT': 'Jelajahi NFT',
  'AI-Powerde Content': 'Konten berbasis AI',
  'AI Image Generator': 'Pembuat Gambar AI',
  'AI Chat Bot': 'Bot obrolan AI',
  'AI Code': 'kode AI',
  'COIN SWAP': 'Tukar Koin',
  'ELONXCAT ALLOCAT STASTISTICS': 'Statistik alokasi ELONXCAT',
  'ELONXCAT STAKING SERVICE IS COMING SOON':
    'Layanan Staking ELONXCAT Segera Hadir',
  'WELCOME TO ELONXCAT STAKING': 'Selamat datang di Taruhan ELONXCAT',
  'WITHDRAW STAKED TOKENS': 'Tarik token yang dipertaruhkan',
  'STAKED BALANCE': 'Saldo Taruhan',
  'YOUR STAKEABLE': 'Saldo tersedia untuk dipertaruhkan',
  'TOTAL STAKED': 'total taruhan',
  'ESTIMATED REWARDS': 'imbalan yang diharapkan',
  'Rewards rate is dynamic': 'Rasio imbalan bersifat dinamis',
  Monthly: 'bulanan',
  Daily: 'lirikan',
  'CURRENT REWARDS': 'imbalan saat ini',
  'TOTAL REWARDS': 'imbalan total',
  'CLAIM REWARDS': 'tuntutan ganti rugi',
  'TOTAL SUPPLY': 'pasokan total',
  'OUR PARTNERS and BACKERS': 'Mitra dan Sponsor Kami',
  'ELONXCAT TEAM': 'Tim ELONXCAT',
  Founder: 'pendiri',
  'Creative Director': 'direktur kreatif',
  'Community manger': 'manajer komunitas',
  'CONNECT YOUR WALLET': 'Koneksi dompet',
  'ENTER PRESALE': 'Partisipasi pra-penjualan',
  'CLAIM ELONXCAT': 'Penagihan ELONXCAT',
  'ROAD MAP': 'peta jalan',
  DEVELOPMENT: 'perkembangan',
  'MEME DOMINATION': 'Donasi Meme',
  'FREQUENTLY ASKED QUESTIONS': 'Pertanyaan yang Sering Diajukan',
  'What is a presale': 'Apa itu pra-penjualan?',
  'What is ELONXCAT': 'Apa itu ELONXCAT?',
  'How do I participate in the presale':
    'Bagaimana cara saya berpartisipasi dalam prapenjualan?',
  'When do I receive my presale tokens':
    'Kapan saya akan menerima token pra-penjualan saya?',
  'What is ELONXCAT staking': 'Apa yang dipertaruhkan ELONXCAT?',
  'How do I stake my tokens': 'Bagaimana cara saya mempertaruhkan token saya?',
  'I staked my tokens during presale':
    'Saya mempertaruhkan token selama pra-penjualan, kapan saya bisa mengklaimnya?',
  'STAY CONNECTED': 'tetap terhubung',
  'PRIVACY POLICY': 'Kebijakan Privasi',
  'TERMS and CONDITIONS': 'Ketentuan Penggunaan',
  'PRESALE ALLOCATION': 'Alokasi pra-penjualan',
  AIRDROP: 'tetesan udara',
  PROJECTFUNDS: 'pendanaan proyek',
  LIQUIDITY: 'likuiditas',
  MARKETING: 'pemasaran',
  EXCHANGES: 'menukarkan',
  'ECOSYSTEM FUNDS': 'dana ekosistem',
  EXPANSION: 'ekspansi',
  'HELP CENTER': 'Pusat Bantuan',
  "If it doesn't work well on mobile":
    'Jika tidak berfungsi dengan baik di ponsel',
  'please try using PC': 'silakan coba gunakan PC',
  'twitter-announce1':
    'Anda dapat memeriksa pengumuman real-time di X (Twitter)',
  'twitter-announce2':
    'Anda tidak dapat melihat postingan lengkap kecuali jika mengikuti.',
  'twitter-announce3': 'Pastikan untuk mengikuti!',

  texts: {
    txt_1:
      'Teknologi kami sendiri yang disebut X mendukung multichain ini. X mendukung fungsionalitas migrasi rantai, memungkinkan pemegang ELONXCAT untuk bergerak bebas melintasi rantai mata uang kripto terkemuka.',
    txt_2:
      'ELONXCAT adalah token meme yang dibangun di jaringan multirantai yang mencakup rantai BNB, Ethereum, Polygon, Solana, dan Tron.',
    txt_3:
      'ELONXCAT berupaya menyatukan komunitas meme mata uang kripto dan menjadi pionir dalam utilitas multi-rantai.',
    txt_4:
      'Tujuan kami adalah menjadikan nama kami bersinar di setiap DEX, setiap CEX, dan di mana pun.',
    txt_5: 'Memanfaatkan',
    txt_6: 'X akan terus memperluas multichain ini di masa depan.',
  },

  'PRESALE TIERS': 'tahap pra-penjualan',

  tiers: {
    tier_txt_1:
      'Harga pra-penjualan awal adalah 0,0000009 USD per kursi, yang akan berubah menjadi Tier2 setelah 20 hari.',
    tier_txt_2:
      'Harga level ini ditetapkan sebesar 0,000001 USD dari pra-penjualan, yang akan berubah menjadi Tier3 setelah 20 hari.',
    tier_txt_3:
      'Harga level ini ditetapkan sebesar 0,000005 USD dari pra-penjualan, yang akan berubah menjadi harga akhir setelah 20 hari.',
    tier_txt_4:
      'Harga pra-penjualan level ini adalah 0,000009 USD, yang akan berakhir dengan berakhirnya pra-penjualan ELONXCAT dengan harga ini.',
    tier_txt_5:
      'ELONXCAT bertujuan untuk menjadi DEX terbaik di BSC dengan fitur seperti pertukaran token, kumpulan likuiditas, dan pertanian hasil.',
    tier_txt_6:
      'ELONXCAT Quest menggabungkan game dan mata uang kripto, memberi Anda kesempatan untuk mendapatkan token Lope melalui misi dan teka-teki di dunia blockchain.',
    tier_txt_7:
      'Proyek ELONXCAT NFT menggabungkan seni digital dengan memecoin ELONXCAT untuk menyediakan koleksi unik dan kaya utilitas untuk meningkatkan keterlibatan komunitas.',
    tier_txt_8:
      'ELONXCAT menggabungkan keuangan dan kreativitas dalam platform yang ramah pengguna dengan mengintegrasikan AI untuk mengobrol, tips perdagangan, pembuatan meme, dan banyak lagi.',
    tier_txt_9:
      'ELONXCAT GET TOKEN GAME adalah permainan blockchain. Ekosistem ELONXCAT mengintegrasikan permainan dengan tokenomics, memungkinkan pemain menyelesaikan berbagai misi dan berpartisipasi dalam aktivitas sosial untuk mendapatkan token. Tujuan dari permainan ini adalah tidak hanya untuk memamerkan teknologi blockchain kami tetapi juga membantu Anda lebih memahami proyek kami sambil mendapatkan uang melalui partisipasi aktif dalam permainan.',
    tier_txt_10:
      'ELONXCAT Coin NFT Marketplace memiliki koleksi unik dengan fitur khusus dan unik.',
    tier_txt_11:
      'NFT Koin ELONXCAT akan terdaftar bersama proyek di semua pasar global serta di pasar proyek itu sendiri.',
    tier_txt_12:
      'Pasar mencakup program airdrop besar yang akan diaktifkan selama kotak NFT dan pra-penjualan token.',
    tier_txt_13:
      'Buat blog yang dioptimalkan untuk SEO, email penjualan, dan banyak lagi... Hemat waktu dan dapatkan hasil luar biasa. Terakhir, ada alat pembuat yang benar-benar dapat digunakan.',
    tier_txt_14:
      'Visualisasikan apa yang Anda impikan. Buat gambar dengan teks. Buat gambar menakjubkan secara instan untuk inovasi artistik Anda dengan generator gambar AI kami.',
    tier_txt_15:
      'Cukup pilih asisten Anda dan ngobrol dengan chatbot yang ramah untuk menghasilkan ide, meningkatkan konten Anda, dan terkadang bahkan membuat Anda tertawa.',
    tier_txt_16:
      'Siap menulis kode secepat kilat? Membuat kode dengan mudah menggunakan AI Code Generator: Memberikan solusi instan untuk kebutuhan pemrograman Anda.',
    tier_txt_17:
      'ElonXCat Swap bertujuan untuk menjadi platform pertukaran terdesentralisasi (DEX) terkemuka di Binance Smart Chain (BSC).',
    tier_txt_18:
      'Disesuaikan untuk koin meme ElonXCat dan ekosistemnya, ElonXCat Swap memfasilitasi pertukaran token yang mulus, menawarkan pool likuiditas, dan memperkenalkan fitur inovatif seperti yield farming dan staking. Fungsionalitas ini bertujuan untuk meningkatkan likuiditas, memastikan perdagangan yang adil, dan berkontribusi pada pertumbuhan keseluruhan komunitas ElonXCat.',
    tier_txt_19:
      'Distribusi hadiah token ELONXCAT akan dilakukan selama dua tahun dengan tarif 708,72 $ELONXCAT per blok Ethereum, dan dapat diklaim setelah klaim diaktifkan.',
    tier_txt_20:
      'Hubungkan dompet Anda dalam hitungan detik menggunakan Metamask atau Trust Wallet.',
    tier_txt_21:
      'Putuskan berapa banyak $ELONXCAT yang ingin Anda beli.  Anda dapat membeli dengan USDT bersama dengan BNB, ETH, dan USDT.',
    tier_txt_22:
      'Setelah pra-penjualan berakhir, Anda dapat mengklaim token $ELONXCAT Anda melalui dompet dan rantai yang sama dengan yang Anda gunakan untuk membeli.',
    tier_txt_23: 'Kelahiran ELONMUSK &',
    tier_txt_24:
      'Pembangunan komunitas Pembuatan token Saluran sosial Kelahiran',
    tier_txt_25: 'KYC & Audit Pompa Pemasaran Pra-penjualan dimulai',
    tier_txt_26:
      'Mulai Pemasaran CoinMarketCap  Lamar Lamar untuk CoinGecko Pembaruan NFT ElonXCAT Pengembangan Game ElonXCAT Pembaruan Staking',
    tier_txt_27:
      'Daftar DEX Pertama  Pembaruan DEXTools  Pembaruan ElonXCAT AI Pembakaran Otomatis',
    tier_txt_28:
      'Pemasaran berkelanjutan Insentif komunitas Kemitraan dengan platform lain Target jumlah pemegang: 1 juta+',
    tier_txt_29:
      'Pra-penjualan memberikan peluang unik bagi investor untuk membeli token atau mata uang kripto baru sebelum peluncuran resminya. Fase akses awal ini memungkinkan kami memanfaatkan potensi proyek sejak awal.',
    tier_txt_30:
      'ELONXCAT adalah koin meme multi-rantai yang benar-benar canggih dan sebenarnya merupakan ELONMUSK & Ekosistem Web3 Tingkat Lanjut: Mempertaruhkan | DEX | AI | permainan | pasar NFT.',
    tier_txt_31:
      'Anda dapat berpartisipasi dalam pra-penjualan di rantai BNB. Hubungkan Dompet Terbaik atau dompet lain ke widget pra-penjualan di atas dan pesan token $ELONXCAT dengan BNB, ETH, atau USDT.',
    tier_txt_32:
      'Setelah pembelian pra-penjualan Anda selesai, Anda dapat menggunakan dompet yang sama untuk mengklaim token Anda sebelum pencatatan DEX pertama. Tanggal penagihan dan pencatatan akan diumumkan di akun media sosial kami.',
    tier_txt_33:
      'Staking adalah tindakan mengirimkan token ke kontrak pintar untuk menguncinya selama jangka waktu tertentu. Hal ini memungkinkan Anda memperoleh lebih banyak token $ELONXCAT dari waktu ke waktu dengan kecepatan dinamis. Staking ELONXCAT akan segera tersedia di jaringan BNB. Untuk informasi lebih lanjut, klik `Staking` di bilah navigasi.',
    tier_txt_34:
      'Untuk mempertaruhkan $ELONXCAT, kunjungi di sini: https://ELONXCAT/en/staking Staking hanya dapat dilakukan dengan $ELONXCAT yang dibeli di BNB. Anda memerlukan $BNB di dompet Anda untuk memproses klaim hadiah de-staking dan staking.',
    tier_txt_35:
      'Jika Anda telah mempertaruhkan $ELONXCAT, Anda harus menunggu periode vesting setidaknya 14 hari sebelum mengklaimnya. Staking hanya dapat dilakukan dengan $BNB yang dibeli di Ethereum. Anda memerlukan $BNB di dompet Anda untuk memproses klaim hadiah de-staking dan staking.',
    tier_txt_36:
      'Staking adalah tindakan mengirimkan token ke kontrak pintar untuk menguncinya selama jangka waktu tertentu. Hal ini memungkinkan Anda memperoleh lebih banyak token $ELONXCAT dari waktu ke waktu dengan kecepatan dinamis. Staking ELONXCAT akan segera tersedia di jaringan BNB. Untuk informasi lebih lanjut, klik `Staking` di bilah navigasi.',
    tier_txt_37:
      'Untuk mempertaruhkan $ELONXCAT, kunjungi di sini: https://ELONXCAT/en/staking Staking hanya dapat dilakukan dengan $ELONXCAT yang dibeli di BNB. Anda memerlukan $BNB di dompet Anda untuk memproses klaim hadiah de-staking dan staking.',
    tier_txt_38:
      'Jika Anda telah mempertaruhkan $ELONXCAT, Anda harus menunggu periode vesting setidaknya 14 hari sebelum mengklaimnya. Staking hanya dapat dilakukan dengan $BNB yang dibeli di Ethereum. Anda memerlukan $BNB di dompet Anda untuk memproses klaim hadiah de-staking dan staking.',
    tier_txt_39:
      'KANTOR ELONXCAT PANAMA: Lantai 43, Bisnis OseaniaPlaza, Tower 2000, Jalan Punta Colon, Urbanisación Punta Pacifica, Panama City, Panama',
    tier_txt_40:
      'Penafian: ELONXCAT adalah koin meme, nilainya hanya didasarkan pada modal budaya universal meme ELONXCAT. Nilai mata uang kripto bisa naik atau turun. Investasikan dengan hati-hati.',
  },

  TOKENBURNINGPLAN: 'RENCANA PEMBAKARAN TOKEN',
  OFFICIALELONXCATVIDEO: 'VIDIO RESMI ELONXCAT',
  steps: {
    step_title_1: 'Setor kripto ke dompet Anda',
    step_title_2: 'Pilih Jaringan',
    step_title_3: 'Hubungkan Dompet',
    step_title_4: 'Beli Token',
    step_title_5: 'Periksa token EXC$ di dompet Anda.',
    step_title_6: 'Nikmati perjalanan kripto Anda dengan ELONXCAT!',
    step_sub_title_1:
      'Silakan setor BNB atau USDT ke dompet kripto Anda (misalnya, MetaMask).',
    step_sub_title_2:
      'Pastikan untuk mengubah jaringan blockchain dompet Anda ke BSC.',
    step_sub_title_3:
      "Temukan tombol 'hubungkan dompet' di widget prapenjualan dan hubungkan dompet pilihan Anda.",
    step_sub_title_4:
      'Masukkan jumlah BNB atau USDT yang ingin Anda tukarkan dengan $EXC dan konfirmasi transaksi di dompet Anda.',
    step_sub_title_5:
      'Jika Anda tidak melihat EXC di dompet Anda, harap tambahkan token. Alamat kontrak token adalah 0xA4b3445A58111ABD407C34402aB59B0FE05bFF5a.',
    step_sub_title_6:
      'Tim ELONXCAT akan mengembangkan ELONXCAT menjadi proyek meme coin terbaik. Mari nikmati perjalanan kripto bersama tim kami!',
    how_to_buy_txt_1: "Video 'CARA MEMBELI $EXC' sudah siap.",
    how_to_buy_txt_2:
      "Klik tombol untuk menonton 'CARA MEMBELI' dalam berbagai bahasa!",
  },
  tokens_burning_plan_description1: 'Setelah terdaftar di bursa,',
  tokens_burning_plan_description2:
    'Tim kami secara bertahap akan membakar lebih dari 90% token.',
  tokens_burning_plan_description3:
    'Jumlah total token akan terus berkurang, dan harga akan meningkat.',
  tokens_burning_plan_description4:
    'Jumlah total token akan terus berkurang, dan harga akan meningkat.',
};
