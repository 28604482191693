const FaqItem = ({ index, question, answer, isOpen, onToggle }) => {
  return (
    <>
      <div
        className='question-item aq_header'
        onClick={() => onToggle(index)}
        style={{ cursor: 'pointer' }}
      >
        <div className='text'>
          <p>{question}</p>
        </div>
        <span
          className='arrow'
          style={{ transform: isOpen ? 'rotate(180deg)' : 'rotate(0deg)' }}
        >
          <img
            src='https://elonxcat.com/assets/images/icons/bottom_arrow.png'
            alt={`elonxcat_${isOpen ? 'close' : 'open'}`}
          />
        </span>
      </div>
      <div
        className='question-item'
        style={{
          display: isOpen ? 'block' : 'none',
          transition: 'all 0.3s ease-in-out',
        }}
      >
        <div className='text'>
          <p>{answer}</p>
        </div>
      </div>
    </>
  );
};

export default FaqItem;
