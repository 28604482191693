import React, { useCallback, useEffect, useState } from 'react';
import useEmblaCarousel from 'embla-carousel-react';
import './EmblaYoutubeCrousel.css';

const EmblaYoutubeCrousel = () => {
  const [emblaRef, emblaApi] = useEmblaCarousel();
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [slidesInView, setSlidesInView] = useState(0);

  const videos = [
    {
      link: 'https://www.youtube.com/embed/TWBy5mO9OFo?si=uIBrzJu-xaMmvADL',
      id: 'TWBy5mO9OFo',
    },
    {
      link: 'https://www.youtube.com/embed/pV6z35pF-6o?si=Pw-1yLpxFr1u4QgG',
      id: 'pV6z35pF-6o',
    },
    {
      link: 'https://www.youtube.com/embed/9FTNifun0T4?si=6pmRIpt5ozp02H2S',
      id: '9FTNifun0T4',
    },
    {
      link: 'https://www.youtube.com/embed/K6WShaxKGnU?si=c3UCSW61X6sYg3IH',
      id: 'K6WShaxKGnU',
    },
    {
      link: 'https://www.youtube.com/embed/8MtMhVfC1aE?si=s8AhYVzlJB3CXwzt',
      id: '8MtMhVfC1aE',
    },
  ];

  const scrollPrev = useCallback(() => {
    if (emblaApi) emblaApi.scrollPrev();
  }, [emblaApi]);

  const scrollNext = useCallback(() => {
    if (emblaApi) emblaApi.scrollNext();
  }, [emblaApi]);

  const onSelect = useCallback(() => {
    if (!emblaApi) return;
    setSelectedIndex(emblaApi.selectedScrollSnap());
    setSlidesInView(emblaApi.scrollSnapList().length);
  }, [emblaApi]);

  useEffect(() => {
    if (!emblaApi) return;
    emblaApi.on('select', onSelect);
    onSelect();
  }, [emblaApi, onSelect]);

  return (
    <div className='carousel'>
      <button
        className='carousel__button carousel__button--prev'
        onClick={scrollPrev}
      >
        ◀
      </button>
      <div className='carousel__viewport' ref={emblaRef}>
        <div className='carousel__container'>
          {videos.map((vid) => (
            <div key={vid.id} className='carousel__slide'>
              <iframe
                width='100%'
                height='315'
                src={vid.link}
                title='YouTube video player'
                frameBorder='0'
                allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
                allowFullScreen
              />
            </div>
          ))}
        </div>
      </div>
      <button
        className='carousel__button carousel__button--next'
        onClick={scrollNext}
      >
        ▶
      </button>

      {/* Dots Navigation */}
      <div className='carousel__dots'>
        {Array.from({ length: slidesInView }).map((_, index) => (
          <button
            key={index}
            className={`carousel__dot ${
              index === selectedIndex ? 'carousel__dot--active' : ''
            }`}
            onClick={() => emblaApi && emblaApi.scrollTo(index)}
          />
        ))}
      </div>
    </div>
  );
};

export default EmblaYoutubeCrousel;
