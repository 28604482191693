import {
  cookieStorage,
  createStorage,
  cookieToInitialState,
  WagmiProvider,
} from "wagmi";
import { WagmiAdapter } from "@reown/appkit-adapter-wagmi";
import { createAppKit } from "@reown/appkit/react";
import { mainnet, bsc } from "@reown/appkit/networks";

import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

const VITE_PROJECT_ID = "98d43b66516e83fe02d8af3f2d541e30";
const projectId = VITE_PROJECT_ID;

const metadata = {
  name: "ELONXCAT",
  description: "ELONXCAT",
  url: "https://elonxcat.com",
  icons: ["https://avatars.githubusercontent.com/u/37784886"],
};

if (!projectId) {
  throw new Error("Project ID is not defined");
}

const networks = [
  bsc,
  //  mainnet
];
const wagmiAdapter = new WagmiAdapter({
  storage: createStorage({
    storage: cookieStorage,
  }),
  ssr: true,
  networks,
  projectId,
});

const queryClient = new QueryClient();

createAppKit({
  adapters: [wagmiAdapter],
  projectId,
  networks,
  defaultNetwork: bsc,
  metadata: metadata,
  features: {
    analytics: false,
    email: false,
    socials: [],
    emailShowWallets: false,
  },
  themeMode: "dark",
});

function Wagmi({ children, cookies }) {
  const initialState = cookieToInitialState(wagmiAdapter.wagmiConfig, cookies);

  return (
    <WagmiProvider
      config={wagmiAdapter.wagmiConfig}
      initialState={initialState}
    >
      <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>
    </WagmiProvider>
  );
}

export default Wagmi;
