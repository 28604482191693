import React, { useContext, useEffect, useRef, useState } from "react";
// import Chart from 'chart.js/auto'; // Ensure you have chart.js installed
// import $ from 'jquery';
import "./all.css";
import "./default.css";
import "./all_bak.css";
import "./main_20241016.css";
import "./ElonXCat.css";
import BarChart from "./BarChart";
import FaqItem from "./FaqItem";
import LanguageSelector from "./LanguageSelector";
import { useTranslate } from "../hooks/useTranslate";
import { useTranslation } from "../context/TranslationContext";
import { formatUnits, parseUnits } from "ethers/lib/utils";
import { toast } from "react-toastify";
import {
  gasEstimationForAll,
  gasEstimationPayable,
  usePresaleContract,
  useTokenContractUSDT,
} from "../utils/connectivity/hooks";
import CopyToClipboard from "react-copy-to-clipboard";
import {
  PresaleAddress,
  TokenAddressUSDT,
  TokenAddressSPLA,
} from "../utils/connectivity/Environment";
import { useEthersSigner } from "../utils/connectivity/ethersAdapters";
import { DataContext } from "../utils/ContextAPI";
import { useAccount, useBalance, useDisconnect, useSwitchChain } from "wagmi";
import { useAppKit } from "@reown/appkit/react";
import moment from "moment";
import YouTubeSlider from "./YoutubeVideoSlider";
import EmblaYoutubeCrousel from "./EmblaYoutubeCrousel";
import bsc from "../assets/images/main/sec2_icon1.png";
import eth from "../assets/images/main/eth-icon.png";
import icon from "../assets/copy.webp";
import popupImage1 from "../assets/Popup_1.png";
import popupImage2 from "../assets/Popup_2.jpeg";
import popupImage3 from "../assets/Popup_3.jpeg";
import popupImage4 from "../assets/Popup_4.jpeg";
import popupImage5 from "../assets/Popup_5.jpeg";
import popupImage6 from "../assets/Popup_6.jpeg";
import popupImage7 from "../assets/Popup_7.jpeg";
import bannerChar from "../assets/images/main/banner-char.png";
import rocketImage from "../assets/images/rocket.png";
import tierArrow from "../assets/images/icons/tier_arrow.png";
import tier3Gif from "../assets/images/icons/tier3.gif";
import howToBuyImage from "../assets/images/main/sec19_1.png";
import tokenBurningPlanImage from "../assets/images/main/sec20_1.png";
import officialElonxcatVideo1 from "../assets/images/main/sec21_1.png";
import officialElonxcatVideo2 from "../assets/images/main/sec21_2.png";
import ethereumLogo from "../assets/images/main/ethereum.png";
import solanaLogo from "../assets/images/main/solana.png";
import tronLogo from "../assets/images/main/tron.png";
import xBlackIcon from "../assets/images/icons/x_black.webp";
import netWorld from "../assets/images/icons/net_world.png";

// flags images
import English from "../assets/images/flags/English.svg";
import Arabic from "../assets/images/flags/Arabic.svg";
import Bulgarian from "../assets/images/flags/Bulgarian.svg";
import Chinese from "../assets/images/flags/Chinese.svg";
import Czech from "../assets/images/flags/Czech.svg";
import Dutch from "../assets/images/flags/Dutch.svg";
import French from "../assets/images/flags/French.svg";
import German from "../assets/images/flags/German.svg";
import Greek from "../assets/images/flags/Greek.svg";
import Hungarian from "../assets/images/flags/Hungarian.svg";
import Indonesian from "../assets/images/flags/Indonesian.svg";
import Italian from "../assets/images/flags/Italian.svg";
import Japanese from "../assets/images/flags/Japanese.svg";
import Korean from "../assets/images/flags/Korean.svg";
import Polish from "../assets/images/flags/Polish.svg";
import Portuguese from "../assets/images/flags/Portuguese.svg";
import Romanian from "../assets/images/flags/Romanian.svg";
import Russian from "../assets/images/flags/Russian.svg";
import Slovak from "../assets/images/flags/Slovak.svg";
import Spanish from "../assets/images/flags/Spanish.svg";
import Thai from "../assets/images/flags/Thai.svg";
import Turkish from "../assets/images/flags/Turkish.svg";
import Vietnamese from "../assets/images/flags/Vietnamese.svg";

import { BsTwitterX } from "react-icons/bs";
import { BiLogoTelegram } from "react-icons/bi";
import { BsYoutube } from "react-icons/bs";
import { RiInstagramFill, RiYoutubeFill } from "react-icons/ri";

import { ethers } from "ethers";

const ElonXCat = () => {
  const { chains, switchChain } = useSwitchChain();
  const { setLoader, loader } = useContext(DataContext);
  const [amount, setamount] = useState();
  const [price, setprice] = useState(0);
  const [sold, setsold] = useState(0);
  const [cost, setcost] = useState("");
  const [tokenType, settokenType] = useState("bnb");
  const [tokenColorType, setTokenColorType] = useState("bnb");
  const [priceText, setPriceText] = useState("1ELONXCAT = $0.000002");

  const { open } = useAppKit();
  const { isConnected, address, chain } = useAccount();

  const [networkBtnColorType, setNetworkBtnColorType] = useState("bsc");
  const { disconnect } = useDisconnect();
  console.log(chain, chain?.id);
  const signer = useEthersSigner({ chain: chain?.id });
  const presaleContract = usePresaleContract(signer);
  const presaleContractVoid = usePresaleContract();
  const tokenContract = useTokenContractUSDT(signer);
  const tokenContractVoid = useTokenContractUSDT();
  const [timer, settimer] = useState({
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
  });
  const [currentPopupIndex, setCurrentPopupIndex] = useState(0);

  useEffect(() => {
    if (chain?.id === 1) {
      setNetworkBtnColorType("eth");
    } else {
      setNetworkBtnColorType("bsc");
    }
  }, [chain]);

  const startTime = async (time) => {
    let interval = setInterval(() => {
      var jun = moment().utc().format("x");
      let untilTime = moment.unix(time).utc().format("x");
      const distance = +untilTime - +jun;

      const days = Math.floor(distance / (1000 * 60 * 60 * 24));
      const hours = Math.floor(
        (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
      );
      const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
      const seconds = Math.floor((distance % (1000 * 60)) / 1000);

      // If the count down is over
      if (distance < 0) {
        clearInterval(interval);

        let nextStartTime = moment.unix(time).add(24, "hours").unix();
        startTime(nextStartTime);
      } else {
        settimer({
          days,
          hours,
          minutes,
          seconds,
        });
      }
    }, 1000);
  };

  const presleAddress = PresaleAddress();
  const usdtAddress = TokenAddressUSDT();
  const splaAddress = TokenAddressSPLA();
  const { data: usdt } = useBalance({
    address,
    token: usdtAddress,
  });
  const { data: bnb } = useBalance({
    address,
  });

  const buyHandler = async () => {
    if (!amount) {
      toast.error("Please enter amount");
      return;
    }
    const decimals = await tokenContractVoid.decimals();
    let limit = await presaleContractVoid.usdtToToken(
      parseUnits("10", decimals)
    );
    limit = Number(formatUnits(limit));

    if (cost < limit) {
      toast.error("Min Purchase 10$");
      return;
    }

    setLoader(true);

    try {
      if (tokenType === "bnb") {
        if (+bnb?.formatted < amount) {
          if (networkBtnColorType === "bsc")
            toast.error("Insufficient BNB Balance");
          else toast.error("Insufficient ETH Balance");
          setLoader(false);
          return;
        }
        const fn = presaleContract.estimateGas.buyToken;
        const data = [];
        const tx = await presaleContract.buyToken({
          value: parseUnits(amount),
          gasLimit: gasEstimationPayable(address, fn, data, parseUnits(amount)),
        });
        await tx.wait();
      } else {
        if (+usdt?.formatted < amount) {
          toast.error("Insufficient USDT Balance");
          setLoader(false);
          return;
        }

        const decimals = await tokenContractVoid.decimals();
        const fn1 = tokenContract.estimateGas.approve;
        const data1 = [presleAddress, parseUnits(amount.toString(), decimals)];

        const approveTx = await tokenContract.approve(...data1, {
          gasLimit: gasEstimationForAll(address, fn1, data1),
        });
        await approveTx.wait();

        const fn2 = presaleContract.estimateGas.buyTokenUSDT;
        const data2 = [parseUnits(amount.toString(), decimals)];
        const tx = await presaleContract.buyTokenUSDT(...data2, {
          gasLimit: gasEstimationForAll(address, fn2, data2),
        });

        await tx.wait();
      }
      toast.success("Tokens Purchased");
      setamount("");
      setcost("");
      getRecord();
    } catch (error) {
      toast.error(error.message);
      console.log(error);
    }
    setLoader(false);
  };

  const getRecord = async () => {
    setLoader(true);
    const sold = await presaleContractVoid.soldToken();

    setsold(Number(formatUnits(sold, 18)));
    const price = await presaleContractVoid.amountRaisedOverall();
    setprice(Number(formatUnits(price)));

    setLoader(false);
  };
  const getQuote = async () => {
    setLoader(true);
    if (tokenType === "bnb") {
      const cost = await presaleContractVoid.nativeToToken(parseUnits(amount));

      setcost(Number(formatUnits(cost)));
    } else {
      const decimals = await tokenContractVoid.decimals();
      console.log(" ------------------");
      console.log("decimals:", decimals);
      console.log(" ------------------");
      const cost = await presaleContractVoid.usdtToToken(
        parseUnits(amount.toString(), decimals)
      );

      setcost(Number(formatUnits(cost)));
    }
    setLoader(false);
  };

  useEffect(() => {
    getRecord();

    let firstEndTime = moment("2025-01-16T08:20:00Z").unix();
    let secondEndTime = moment("2025-01-31T23:59:59Z").unix();

    let currentTime = moment().unix();
    if (currentTime >= firstEndTime) {
      startTime(secondEndTime);
    } else {
      startTime(firstEndTime);
    }
  }, []);
  useEffect(() => {
    const debounce = setTimeout(() => {
      if (amount) getQuote();
    }, 1000);
    return () => clearTimeout(debounce);
  }, [amount, tokenType, chain]);
  // for chart
  // const tokenPosLeftRef = useRef(null);
  // const totalSupplyRef = useRef(null);
  const [openQuestions, setOpenQuestions] = useState({});
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  //const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isMobileLanguageOpen, setIsMobileLanguageOpen] = useState(false);
  const { currentLanguage, setLanguage } = useTranslation();

  const t = useTranslate();

  const toggleMobileLanguage = () => {
    setIsMobileLanguageOpen(!isMobileLanguageOpen);
  };

  const handleMobileLanguageSelect = (language) => {
    handleLanguageChange(language.code);
    setIsMobileLanguageOpen(false);
  };
  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const toggleQuestion = (index) => {
    setOpenQuestions((prev) => ({
      ...prev,
      [index]: !prev[index],
    }));
  };

  const handleLanguageChange = (languageCode) => {
    console.log("Selected language:", languageCode);
    setLanguage(languageCode);
    // Implement your language change logic here
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (isMenuOpen && !event.target.closest(".mobile-menu")) {
        setIsMenuOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isMenuOpen]);

  useEffect(() => {
    if (isMenuOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "unset";
    }

    return () => {
      document.body.style.overflow = "unset";
    };
  }, [isMenuOpen]);

  useEffect(() => {
    // Initialize the first chart for token_posleft
    // for chart
    // const tokenCtx = tokenPosLeftRef.current.getContext("2d");
    const tokenData = {
      labels: ["데이터1", "데이터2", "데이터3"],
      datasets: [
        {
          data: [30, 20, 50],
          backgroundColor: ["#ff6384", "#36a2eb", "#cc65fe"],
          hoverBackgroundColor: ["#ff6384", "#36a2eb", "#cc65fe"],
          borderWidth: 0,
        },
      ],
    };

    const tokenOptions = {
      cutout: "70%",
      layout: {
        padding: 20,
      },
      plugins: {
        legend: {
          display: false,
        },
        tooltip: {
          callbacks: {
            label: function (tooltipItem) {
              let total = tooltipItem.dataset.data.reduce(
                (acc, val) => acc + val,
                0
              );
              let value = tooltipItem.raw;
              let percentage = ((value / total) * 100).toFixed(2);
              return `${tooltipItem.label}: ${percentage}%`;
            },
          },
        },
      },
      elements: {
        arc: {
          borderWidth: 10,
          borderColor: "#ffffff",
        },
      },
    };
    // for chart
    // const tokenChart = new Chart(tokenCtx, {
    //   type: "doughnut",
    //   data: tokenData,
    //   options: tokenOptions,
    // });

    // Initialize the second chart for total_supply
    // const totalCtx = totalSupplyRef.current.getContext("2d");
    const totalData = {
      labels: ["Total Supply"],
      datasets: [
        {
          data: [5000000000000], // Example data
          backgroundColor: ["#ffcc00"],
          hoverBackgroundColor: ["#ffcc00"],
          borderWidth: 0,
        },
      ],
    };

    const totalOptions = {
      cutout: "70%",
      layout: {
        padding: 20,
      },
      plugins: {
        legend: {
          display: false,
        },
        tooltip: {
          callbacks: {
            label: function (tooltipItem) {
              return `${tooltipItem.label}: ${tooltipItem.raw}`;
            },
          },
        },
      },
      elements: {
        arc: {
          borderWidth: 10,
          borderColor: "#ffffff",
        },
      },
    };

    // const totalChart = new Chart(totalCtx, {
    //   type: "doughnut",
    //   data: totalData,
    //   options: totalOptions,
    // });

    // Cleanup function to destroy the charts on component unmount
    return () => {
      // tokenChart.destroy();
      // totalChart.destroy();
    };
  }, []);

  function open_audit() {
    window.open("https://coinsult.net/projects/elonxcat/");
    return false;
  }

  const faqData = [
    {
      question: t("What is a presale"),
      answer: t("tiers.tier_txt_29"),
    },
    {
      question: t("What is ELONXCAT"),
      answer: t("tiers.tier_txt_30"),
    },
    {
      question: t("How do I participate in the presale"),
      answer: t("tiers.tier_txt_31"),
    },
    {
      question: t("When do I receive my presale tokens"),
      answer: t("tiers.tier_txt_32"),
    },
    {
      question: t("What is ELONXCAT staking"),
      answer: t("tiers.tier_txt_33"),
    },
    {
      question: t("How do I stake my tokens"),
      answer: t("tiers.tier_txt_34"),
    },
    {
      question: t("I staked my tokens during presale"),
      answer: t("tiers.tier_txt_35"),
    },
  ];

  const languages = [
    { code: "en", name: "English", flag: English },
    { code: "ar", name: "Arabic", flag: Arabic },
    { code: "bg", name: "Bulgarian", flag: Bulgarian },
    { code: "cn", name: "Chinese (simplified)", flag: Chinese },
    { code: "cnt", name: "Chinese (traditional)", flag: Chinese },
    { code: "cz", name: "Czech", flag: Czech },
    { code: "nl", name: "Dutch", flag: Dutch },
    { code: "fr", name: "French", flag: French },
    { code: "de", name: "German", flag: German },
    { code: "el", name: "Greek", flag: Greek },
    { code: "hu", name: "Hungarian", flag: Hungarian },
    { code: "id", name: "Indonesian", flag: Indonesian },
    { code: "it", name: "Italian", flag: Italian },
    { code: "jp", name: "Japanese", flag: Japanese },
    { code: "kr", name: "Korean", flag: Korean },
    { code: "pl", name: "Polish", flag: Polish },
    { code: "pr", name: "Portuguese", flag: Portuguese },
    { code: "rm", name: "Romanian", flag: Romanian },
    { code: "ru", name: "Russian", flag: Russian },
    { code: "sl", name: "Slovak", flag: Slovak },
    { code: "es", name: "Spanish", flag: Spanish },
    { code: "th", name: "Thai", flag: Thai },
    { code: "tu", name: "Turkish", flag: Turkish },
    { code: "vu", name: "Vietnamese", flag: Vietnamese },
  ];

  const popups = [
    popupImage1,
    popupImage2,
    popupImage3,
    popupImage4,
    popupImage5,
    popupImage6,
    // popupImage7,
  ];

  const closePopup = () => {
    if (currentPopupIndex < popups.length - 1) {
      setCurrentPopupIndex(currentPopupIndex + 1);
    } else {
      setCurrentPopupIndex(-1);
    }
  };

  return (
    <div>
      {currentPopupIndex >= 0 && (
        <div className="popup-overlay">
          <div className="popup-content">
            <button className="close-button" onClick={closePopup}>
              &times;
            </button>
            <img
              src={popups[currentPopupIndex]}
              alt={`elonxcat_Popup ${currentPopupIndex + 1}`}
              style={
                currentPopupIndex === 1 || currentPopupIndex === 2
                  ? { cursor: "pointer" }
                  : currentPopupIndex === 0
                  ? { width: "700px", height: "auto", objectFit: "contain" }
                  : {}
              }
              onClick={() => {
                if (currentPopupIndex === 1) {
                  window.open("https://bit.ly/HOWTOBUYEASY", "_blank");
                } else if (currentPopupIndex === 2) {
                  window.open("https://t.me/ELONXCAT_PORTAL", "_blank");
                }
              }}
            />
          </div>
        </div>
      )}
      <div id="wrap">
        <div
          // src={rocketImage}
          // alt='elonxcat_Rocket'
          className="rocket-overlay"
          // style={{ display: 'none' }}
        ></div>
        <header>
          <div className="container-2">
            <div className="hd-wrap">
              <div className="left-area">
                <div id="logo">
                  <a href="#">
                    <img
                      src="https://elonxcat.com/assets/images/logo.png"
                      alt="ELONXCAT"
                    />
                  </a>
                </div>
                <div id="main-menu">
                  <ul>
                    <li>
                      <a href="/notice">{t("NOTICE")}</a>
                    </li>
                    <li>
                      <a href="#">{t("ABOUT")}</a>
                    </li>
                    <li>
                      <a href="#section9">{t("TOKENOMICS")}</a>
                    </li>
                    <li>
                      <a href="#section10">{t("STAKING")}</a>
                    </li>
                    <li>
                      <a href="#section15">{t("HOW TO BUY")}</a>
                    </li>
                    <li>
                      <a href="#section16">{t("ROADMAP")}</a>
                    </li>
                    <li>
                      <a href="#section17">{t("FAQ")}</a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="right-area">
                <div id="util-menu">
                  <ul>
                    <li>
                      <a
                        href="https://x.com/elonxcat"
                        target="_blank"
                        rel="noreferrer"
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          border: "1px solid white",
                          borderRadius: "999px",
                          backgroundColor: "#190B18",
                        }}
                      >
                        <BsTwitterX
                          style={{
                            width: "16px",
                            height: "16px",
                          }}
                        />
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://t.me/ELONXCAT_PORTAL"
                        target="_blank"
                        rel="noreferrer"
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          border: "1px solid white",
                          borderRadius: "999px",
                          backgroundColor: "#190B18",
                        }}
                      >
                        <BiLogoTelegram
                          style={{
                            width: "20px",
                            height: "20px",
                          }}
                        />
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://www.youtube.com/@ELONXCAT"
                        target="_blank"
                        rel="noreferrer"
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          border: "1px solid white",
                          borderRadius: "999px",
                          backgroundColor: "#190B18",
                        }}
                      >
                        <BsYoutube
                          style={{
                            width: "18px",
                            height: "18px",
                          }}
                        />
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://www.instagram.com/elonxcat"
                        target="_blank"
                        rel="noreferrer"
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          border: "1px solid white",
                          borderRadius: "999px",
                          backgroundColor: "#190B18",
                        }}
                      >
                        <RiInstagramFill
                          style={{
                            width: "20px",
                            height: "20px",
                          }}
                        />
                      </a>
                    </li>
                    <li className="buy-now">
                      <button
                        type="button"
                        onClick={() =>
                          document
                            .getElementById("section1")
                            .scrollIntoView({ behavior: "smooth" })
                        }
                      >
                        {t("BUY NOW")}
                      </button>
                    </li>
                  </ul>
                </div>

                {/* language selector */}
                <LanguageSelector
                  onLanguageChange={handleLanguageChange}
                  languages={languages}
                />

                <div className={`mobile-menu ${isMenuOpen ? "active" : ""}`}>
                  <button
                    type="button"
                    className={`mobile-menu-btn ${isMenuOpen ? "active" : ""}`}
                    onClick={toggleMenu}
                  >
                    <span />
                    <span />
                    <span />
                  </button>
                  <div
                    className={`mobile-menu-wrap ${isMenuOpen ? "show" : ""}`}
                    style={{
                      display: isMenuOpen ? "block" : "none",
                    }}
                  >
                    <ul className="mobile-menu-list">
                      <li>
                        <a href="/notice">{t("NOTICE")}</a>
                      </li>
                      <li>
                        <a href="#">{t("ABOUT")}</a>
                      </li>
                      <li>
                        <a href="#section9">{t("TOKENOMICS")}</a>
                      </li>
                      <li>
                        <a href="#section10">{t("STAKING")}</a>
                      </li>
                      <li>
                        <a href="#section15">{t("HOW TO BUY")}</a>
                      </li>
                      <li>
                        <a href="#section16">{t("ROADMAP")}</a>
                      </li>
                      <li>
                        <a href="#section17">{t("FAQ")}</a>
                      </li>
                    </ul>
                    <ul
                      className="mobile-util"
                      style={{
                        display: "flex",
                        gap: "12px",
                      }}
                    >
                      <li>
                        <a
                          href="https://x.com/elonxcat"
                          target="_blank"
                          rel="noreferrer"
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            border: "1px solid white",
                            borderRadius: "999px",
                            backgroundColor: "#190B18",
                            width: 34,
                            height: 34,
                          }}
                        >
                          <BsTwitterX
                            style={{
                              width: "16px",
                              height: "16px",
                            }}
                          />
                        </a>
                      </li>
                      <li>
                        <a
                          href="https://t.me/ELONXCAT_PORTAL"
                          target="_blank"
                          rel="noreferrer"
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            border: "1px solid white",
                            borderRadius: "999px",
                            backgroundColor: "#190B18",
                            width: 34,
                            height: 34,
                          }}
                        >
                          <BiLogoTelegram
                            style={{
                              width: "16px",
                              height: "16px",
                            }}
                          />
                        </a>
                      </li>
                      <li>
                        <a
                          href="https://www.youtube.com/@ELONXCAT"
                          target="_blank"
                          rel="noreferrer"
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            border: "1px solid white",
                            borderRadius: "999px",
                            backgroundColor: "#190B18",
                            width: 34,
                            height: 34,
                          }}
                        >
                          <BsYoutube
                            style={{
                              width: "16px",
                              height: "16px",
                            }}
                          />
                        </a>
                      </li>
                      <li>
                        <a
                          href="https://www.instagram.com/elonxcat"
                          target="_blank"
                          rel="noreferrer"
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            border: "1px solid white",
                            borderRadius: "999px",
                            backgroundColor: "#190B18",
                            width: 34,
                            height: 34,
                          }}
                        >
                          <RiInstagramFill
                            style={{
                              width: "16px",
                              height: "16px",
                            }}
                          />
                        </a>
                      </li>
                    </ul>
                    <div
                      className={`mobile-language ${
                        isMobileLanguageOpen ? "active" : ""
                      }`}
                    >
                      <div
                        type="button"
                        className="language-btn"
                        onClick={toggleMobileLanguage}
                      >
                        <img
                          src={currentLanguage.flag}
                          alt={`elonxcat_${currentLanguage.name} flag`}
                          className="language-flag"
                        />
                        <span className="language-text">
                          {currentLanguage.name}
                        </span>
                      </div>
                      <ul className="language-list">
                        {languages.map((language) => (
                          <button
                            key={language.code}
                            type="button"
                            className="language-item"
                            onClick={() => handleMobileLanguageSelect(language)}
                          >
                            <img
                              src={language.flag}
                              alt={`elonxcat_${language.name} flag`}
                              className="language-flag"
                            />
                            <span>{language.name}</span>
                          </button>
                        ))}
                      </ul>
                    </div>
                    <button
                      type="button"
                      className="mobile-buy-now"
                      onClick={() =>
                        document
                          .getElementById("section1")
                          .scrollIntoView({ behavior: "smooth" })
                      }
                    >
                      {t("BUY NOW")}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </header>
        <div id="contents">
          <div id="main-banner">
            <div className="container-2">
              <div className="banner-wrap">
                <div className="left-area">
                  <div className="banner-char">
                    <div data-aos="aos-init aos-animate" data-aos-delay={200} />
                    <img
                      src={bannerChar}
                      alt="elonxcat_Banner Image"
                      className="pc"
                    />
                    <img
                      src="https://elonxcat.com/assets/images/main/banner-char-mobile.png"
                      alt="elonxcat_Banner Image"
                      className="mobile"
                    />
                  </div>
                  {/* <div class="char-text">
                <p>
                ENJOY SPACE TRAVEL<br />
                WITH COOL CATS!
                </p>
            </div> */}
                </div>
                <div className="right-area">
                  <div className="banner-text">
                    <div className="top-logo">
                      <img
                        src="https://elonxcat.com/assets/images/big-logo.png"
                        alt="ELONXCAT"
                        className="pc"
                      />
                      <img
                        src="https://elonxcat.com/assets/images/big-color-logo.png"
                        alt="ELONXCAT"
                        className="mobile"
                      />
                    </div>
                    <div className="text-parent">
                      <h1 className="pc">
                        <div>{t("ELONXCAT is the real")}</div>
                        <div>{t("ELONMUSK")}</div>
                        <div>{t("SpaceX & X")}</div>
                        <div>{t("meme project")}</div>
                      </h1>
                      <h2 className="mobile">
                        <div>{t("Let's go to Mars by SpaceX")}</div>
                        <div>{t("We always support SpaceX")}</div>
                      </h2>
                      <h3>
                        <div>{t("Advanced Web3 Ecosystem")}</div>
                        <div>{t("STAKING")} | DEX | AI | GAME | NFT MARKET</div>
                        <div>KYC+ , Audited</div>
                      </h3>
                    </div>
                  </div>
                  {/* <div className='project-info'>
                    <div className='info-item'>
                      <h2 className='name'>{t('Potential of project')}</h2>
                      <p>750+</p>
                    </div>
                    <div className='info-item'>
                      <h2 className='name'>{t('Market cap Target')}</h2>
                      <p>$150.52M</p>
                    </div>
                    <div className='info-item'>
                      <span className='name'>{t('Investors')}</span>
                      <p>1750</p>
                    </div>
                  </div> */}
                  <div className="banner-btn-container">
                    <div className="banner-btn">
                      <a
                        href="/ElonXCat_WhitePaper 2.0.pdf"
                        className="paper-btn"
                        type="button"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {t("WHITE PAPER")}
                      </a>
                      <button
                        type="button"
                        className="audit-btn"
                        onClick={open_audit}
                      >
                        {t("AUDIT")}
                      </button>
                    </div>
                    <button
                      type="button"
                      className="help-center-btn"
                      onClick={() => {
                        window.open("https://t.me/elonxcatofficial", "_blank");
                      }}
                    >
                      {t("HELP CENTER")}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div id="twitter-notice">
            <div className="button-container">
              <button
                type="button"
                className="logo"
                onClick={() => {
                  window.open("https://x.com/elonxcat", "_blank");
                }}
              >
                <img src={xBlackIcon} alt="elonxcat_x_icon" />
              </button>
              <span className="click-text">(Click)</span>
            </div>
            <div className="text-container">
              <span className="text">{t("twitter-announce1")}</span>
              <span className="text">{t("twitter-announce2")}</span>
              <span className="text">{t("twitter-announce3")}</span>
            </div>
          </div>
          <div id="section">
            <div id="section1">
              <div className="container">
                <div className="sec-wrap">
                  <div className="left-area">
                    <div className="your-elonxcat">
                      <div className="title">
                        <img
                          src={tier3Gif}
                          alt=""
                          style={{ width: "100%", padding: "0 20px" }}
                        />
                        {/* <h2>
                               {t('Presale will start soon. Join the Presale')}{' '}
                             </h2>
                             <div className='tier'>
                               <span>Tier 1</span>
                               <img src={tierArrow} alt='' />
                             </div> */}
                      </div>
                      <div className="count-down">
                        <div className="time-item">
                          <span>{timer.days}</span>
                        </div>
                        <div className="clones-item">
                          <span>:</span>
                        </div>
                        <div className="time-item">
                          <span>{timer.hours}</span>
                        </div>
                        <div className="clones-item">
                          <span>:</span>
                        </div>
                        <div className="time-item">
                          <span>{timer.minutes}</span>
                        </div>
                        <div className="clones-item">
                          <span>:</span>
                        </div>
                        <div className="time-item">
                          <span>{timer.seconds}</span>
                        </div>
                      </div>
                      <div className="my-credit">
                        <ul style={{ marginBottom: "0" }}>
                          <li>
                            {t("Raised")} : ${price.toFixed(3)}
                            <b />
                          </li>
                          <li>
                            {t("ELONXCAT sold")} : {sold.toFixed(3)}
                            <b />
                          </li>
                        </ul>
                      </div>
                      <div className="balance-text">
                        <p style={{ marginBottom: "0" }}>
                          Balance :{" "}
                          {tokenType === "bnb"
                            ? +bnb?.formatted
                            : +usdt?.formatted}
                        </p>
                      </div>

                      <div className="bottom-btn">
                        <div className="unit-btn">
                          <button
                            type="button"
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              gap: 5,
                              background:
                                networkBtnColorType === "bsc"
                                  ? "linear-gradient(to right, #00daff, #fc08b3)"
                                  : "transparent",
                              border:
                                networkBtnColorType === "bsc"
                                  ? ""
                                  : "2px solid white",
                            }}
                            onClick={() => {
                              switchChain({ chainId: 56 });
                              setNetworkBtnColorType("bsc");
                            }}
                          >
                            <img src={bsc} alt="" width={"20px"} />
                            BSC Network
                          </button>
                        </div>
                        <div className="unit-btn">
                          <button
                            type="button"
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              gap: 5,
                              background:
                                networkBtnColorType === "eth"
                                  ? "linear-gradient(to right, #00daff, #fc08b3)"
                                  : "transparent",
                              border:
                                networkBtnColorType === "eth"
                                  ? ""
                                  : "2px solid white",
                            }}
                            onClick={() => {
                              alert("Service Under Preparation");
                              // setNetworkBtnColorType("eth");
                              // switchChain({ chainId: 1 });
                            }}
                          >
                            <img src={eth} alt="" width={"20px"} />
                            ETH Network
                          </button>
                        </div>
                        <div className="unit-btn">
                          <button
                            type="button"
                            id="btnBnb"
                            onClick={() => {
                              settokenType("bnb");
                              setTokenColorType("bnb");
                            }}
                            style={{
                              backgroundColor:
                                tokenColorType === "bnb"
                                  ? "#d5d500"
                                  : "#00b1ef",
                            }}
                          >
                            {networkBtnColorType === "bsc" ? "BNB" : "ETH"}
                          </button>
                        </div>
                        <div className="unit-btn">
                          <button
                            type="button"
                            id="btnUsdt"
                            onClick={() => {
                              settokenType("usdt");
                              setTokenColorType("usdt");
                            }}
                            style={{
                              backgroundColor:
                                tokenColorType === "usdt"
                                  ? "#03c003"
                                  : "#00b1ef",
                            }}
                          >
                            USDT
                          </button>
                        </div>
                        <p
                          style={{ marginBottom: "0" }}
                          className="bottom-text"
                        >
                          {priceText}
                        </p>
                      </div>
                      <ul className="inp-list">
                        <li className="inp-item">
                          <label htmlFor="inp1">
                            {priceText}
                            <div>Buy Min $10</div>
                          </label>
                          <div
                            className={
                              tokenType === "bnb"
                                ? networkBtnColorType === "eth"
                                  ? "inp-box active3"
                                  : "inp-box active1"
                                : "inp-box active2"
                            }
                          >
                            <input
                              type="text"
                              id="inp1"
                              value={amount}
                              onChange={(e) => setamount(e.target.value)}
                            />
                          </div>
                        </li>
                        <li className="inp-item">
                          <label htmlFor="inp2">
                            Amount in <b>ELONXCAT</b> receive
                          </label>
                          <div className="inp-box active">
                            {/*  */}
                            <input
                              type="text"
                              id="inp2"
                              readOnly
                              value={cost}
                            />
                          </div>
                        </li>
                      </ul>
                      <div className="wallet-btn">
                        {isConnected ? (
                          <>
                            <button type="button" onClick={() => buyHandler()}>
                              {loader ? "Processing..." : "Buy"}
                            </button>
                            <br />
                            <br />
                            <button type="button" onClick={disconnect}>
                              Disconnect
                            </button>
                          </>
                        ) : (
                          <button type="button" onClick={open}>
                            <h3 style={{ marginBottom: "0" }}>
                              CONNECT WALLET
                            </h3>
                          </button>
                        )}
                      </div>

                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          gap: 10,
                        }}
                      >
                        <p
                          style={{
                            fontSize: "16px",
                            marginBottom: "0",
                          }}
                        >
                          Contract Address:
                        </p>
                        <p
                          style={{
                            fontSize: "16px",
                            color: "blue",
                            marginBottom: "0",
                          }}
                        >
                          {splaAddress.substring(0, 10) +
                            "..." +
                            splaAddress.substring(32, 42)}
                        </p>
                        <CopyToClipboard
                          text={splaAddress}
                          onCopy={() => toast.success("Copied to clipboard")}
                        >
                          <img
                            src={icon}
                            width="15px"
                            alt="elonxcat_copy_icon"
                            style={{
                              cursor: "pointer",
                              marginBottom: "7px",
                            }}
                          />
                        </CopyToClipboard>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          gap: 10,
                        }}
                      >
                        <p
                          style={{
                            fontSize: "16px",
                            marginBottom: "0",
                          }}
                        >
                          Presale Address:
                        </p>
                        <p
                          style={{
                            fontSize: "16px",
                            color: "blue",
                            marginBottom: "0",
                          }}
                        >
                          {presleAddress.substring(0, 10) +
                            "..." +
                            presleAddress.substring(32, 42)}
                        </p>
                        <CopyToClipboard
                          text={presleAddress}
                          onCopy={() => toast.success("Copied to clipboard")}
                        >
                          <img
                            src={icon}
                            width="15px"
                            alt="elonxcat_copy_icon"
                            style={{
                              cursor: "pointer",
                              marginBottom: "7px",
                            }}
                          />
                        </CopyToClipboard>
                      </div>
                      <div className="try-pc">
                        <p>
                          {t("Never deposit money to the above two address !")}
                        </p>
                        <p>
                          {t(
                            "Tokens can only be purchased through a wallet connection"
                          )}
                          .
                        </p>
                      </div>
                      <div className="try-pc">
                        <p>{t("If it doesn't work well on mobile")},</p>
                        <p>{t("please try using PC")}.</p>
                      </div>
                    </div>
                  </div>
                  <div className="right-area">
                    <img
                      src="https://elonxcat.com/assets/images/main/sec1_right.png"
                      alt="ELONXCAT"
                    />
                  </div>
                </div>
              </div>
            </div>
            <EmblaYoutubeCrousel />
            <div id="section21">
              <div className="container">
                <div className="sec-title">
                  <h2>{t("OFFICIAL ELONXCAT VIDEO")}</h2>
                </div>
                <div className="wrap">
                  <div className="img-wrap1">
                    <img src={officialElonxcatVideo1} alt="elonxcat_cat" />
                  </div>
                  <div className="video-wrap">
                    <iframe
                      width="100%"
                      height="100%"
                      src="https://www.youtube.com/embed/eRoFEk8dd8Q?si=jbBUdGdlbaitPPdC"
                      title="YouTube video player"
                      frameBorder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowFullScreen
                    />
                  </div>
                  <div className="img-wrap2">
                    <img src={officialElonxcatVideo2} alt="elonxcat_cat" />
                  </div>
                </div>
              </div>
            </div>
            <div id="section19">
              <div className="container">
                <div className="sec-title">
                  <h2>{t("HOW TO BUY")} $EXC</h2>
                </div>
                <div className="step-wrap">
                  <div className="step">
                    <div className="step-title">Step 1</div>
                    <div className="step-sub-title">
                      {t("steps").step_title_1}
                    </div>
                    <div className="step-description">
                      {t("steps").step_sub_title_1}
                    </div>
                  </div>
                  <div className="step">
                    <div className="step-title">Step 2</div>
                    <div className="step-sub-title">
                      {t("steps").step_title_2}
                    </div>
                    <div className="step-description">
                      {t("steps").step_sub_title_2}
                    </div>
                  </div>
                  <div className="step">
                    <div className="step-title">Step 3</div>
                    <div className="step-sub-title">
                      {t("steps").step_title_3}
                    </div>
                    <div className="step-description">
                      {t("steps").step_sub_title_3}
                    </div>
                  </div>
                  <div className="step">
                    <div className="step-title">Step 4</div>
                    <div className="step-sub-title">
                      {t("steps").step_title_4}
                    </div>
                    <div className="step-description">
                      {t("steps").step_sub_title_4}
                    </div>
                  </div>
                  <div className="step">
                    <div className="step-title">Step 5</div>
                    <div className="step-sub-title">
                      {t("steps").step_title_5}
                    </div>
                    <div className="step-description">
                      {t("steps").step_sub_title_5}
                    </div>
                  </div>
                  <div className="step">
                    <div className="step-title">Step 6</div>
                    <div className="step-sub-title">
                      {t("steps").step_title_6}
                    </div>
                    <div className="step-description">
                      {t("steps").step_sub_title_6}
                    </div>
                  </div>
                </div>
                <div className="img-wrap">
                  <a target="_black" href="https://bit.ly/HOWTOBUYEASY">
                    <img src={howToBuyImage}></img>
                  </a>
                </div>
                <div className="how-to-buy">
                  {t("steps").how_to_buy_txt_1}
                  <br /> {t("steps").how_to_buy_txt_2}
                </div>
              </div>
            </div>
            <div id="section13">
              <div className="sec-title">
                <h2>{t("MULTI BRIDGE UTILITY")}</h2>
              </div>
              <div className="container-3">
                <div className="sec-wrap">
                  <div className="left-area">
                    <img
                      src="https://elonxcat.com/assets/images/main/sec13_right.png"
                      alt="elonxcat_MULTI BRIDGE UTILITY"
                    />
                  </div>
                  <div className="right-area">
                    <div className="text-parent">
                      <p>
                        {t("texts.txt_1")} <br />
                        <br />
                        {t("texts.txt_2")}
                        <br />
                        <br />
                        {t("texts.txt_3")}
                        <br />
                        <br />
                        {t("texts.txt_4")}
                        <br />
                        <br />
                        {t("texts.txt_5")}
                        <br />
                        <br />
                        {t("texts.txt_6")}{" "}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div id="section2">
              <div className="container">
                <div className="sec-title">
                  <h2>
                    ELONXCAT
                    <br />
                    {t("PRESALE TIERS")}
                  </h2>
                  <p>
                    <b>ELONXCAT</b>-{t("Total Supply")} : 500,000,000,000,000
                  </p>
                </div>
                <div className="sec-list">
                  <div className="sec-item">
                    <div className="box">
                      <div className="icon">
                        <img
                          src="https://elonxcat.com/assets/images/icons/coin_1.png"
                          alt="elonxcat_Tier 1"
                        />
                      </div>
                      <div className="title">
                        <h3>Tier 1</h3>
                      </div>
                      <div className="price">
                        <p>$0.0000009</p>
                      </div>
                    </div>
                  </div>
                  <div className="sec-item">
                    <div className="box">
                      <div className="icon">
                        <img
                          src="https://elonxcat.com/assets/images/icons/coin_2.png"
                          alt="elonxcat_Tier 2"
                        />
                      </div>
                      <div className="title">
                        <h3>Tier 2</h3>
                      </div>
                      <div className="price">
                        <p>$0.000001</p>
                      </div>
                    </div>
                  </div>
                  <div className="sec-item">
                    <div className="box">
                      <div className="icon">
                        <img
                          src="https://elonxcat.com/assets/images/icons/coin_3.png"
                          alt="elonxcat_Tier 3"
                        />
                      </div>
                      <div className="title">
                        <h3>Tier 3</h3>
                      </div>
                      <div className="price">
                        <p>$0.000002</p>
                      </div>
                    </div>
                  </div>
                  <div className="sec-item">
                    <div className="box">
                      <div className="icon">
                        <img
                          src="https://elonxcat.com/assets/images/icons/coin_4.png"
                          alt="elonxcat_Tier 4"
                        />
                      </div>
                      <div className="title">
                        <h3>Tier 4</h3>
                      </div>
                      <div className="price">
                        <p>$0.000003</p>
                      </div>
                    </div>
                  </div>
                  <div className="sec-item">
                    <div className="box">
                      <div className="icon">
                        <img
                          src="https://elonxcat.com/assets/images/icons/coin_4.png"
                          alt="elonxcat_Tier 4"
                        />
                      </div>
                      <div className="title">
                        <h3>Tier 5</h3>
                      </div>
                      <div className="price">
                        <p>$0.000004</p>
                      </div>
                    </div>
                  </div>
                  <div className="sec-item">
                    <div className="box">
                      <div className="icon">
                        <img
                          src="https://elonxcat.com/assets/images/icons/coin_4.png"
                          alt="elonxcat_Tier 4"
                        />
                      </div>
                      <div className="title">
                        <h3>Tier 6</h3>
                      </div>
                      <div className="price">
                        <p>$0.000005</p>
                      </div>
                    </div>
                  </div>
                  <div className="sec-item">
                    <div className="box">
                      <div className="icon">
                        <img
                          src="https://elonxcat.com/assets/images/icons/coin_4.png"
                          alt="elonxcat_Tier 4"
                        />
                      </div>
                      <div className="title">
                        <h3>Tier 7</h3>
                      </div>
                      <div className="price">
                        <p>$0.000006</p>
                      </div>
                    </div>
                  </div>
                  <div className="sec-item">
                    <div className="box">
                      <div className="icon">
                        <img
                          src="https://elonxcat.com/assets/images/icons/coin_4.png"
                          alt="elonxcat_Tier 4"
                        />
                      </div>
                      <div className="title">
                        <h3>Tier 8</h3>
                      </div>
                      <div className="price">
                        <p>$0.000007</p>
                      </div>
                    </div>
                  </div>
                  <div className="sec-item">
                    <div className="box">
                      <div className="icon">
                        <img
                          src="https://elonxcat.com/assets/images/icons/coin_4.png"
                          alt="elonxcat_Tier 4"
                        />
                      </div>
                      <div className="title">
                        <h3>Tier 9</h3>
                      </div>
                      <div className="price">
                        <p>$0.000008</p>
                      </div>
                    </div>
                  </div>
                  <div className="sec-item">
                    <div className="box">
                      <div className="icon">
                        <img
                          src="https://elonxcat.com/assets/images/icons/coin_4.png"
                          alt="elonxcat_Tier 4"
                        />
                      </div>
                      <div className="title">
                        <h3>Tier 10</h3>
                      </div>
                      <div className="price">
                        <p>$0.000009</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* <div class="sec3-back-image">
        <img src="https://elonxcat.com/assets/images/main/sec3_back.png" alt="elonxcat_background" />
        </div> */}
            <div id="section20">
              <div className="container">
                <div className="sec-title">
                  <h2>{t("TOKENBURNINGPLAN")}</h2>
                </div>
                <div className="wrap">
                  <div className="description">
                    {t("tokens_burning_plan_description1")}
                    <br /> {t("tokens_burning_plan_description2")}
                    <br /> {t("tokens_burning_plan_description3")}
                  </div>
                  <div className="img-wrap">
                    <img src={tokenBurningPlanImage}></img>
                  </div>
                </div>
              </div>
            </div>
            {/* <div id='section3'>
              <div className='container'>
                <div className='sec-title'>
                  <h2>{t('ELONXCAT ACHIEVEMENTS')}</h2>
                </div>
                <div className='sec-list'>
                  <div className='sec-item'>
                    <div className='box'>
                      <div className='icon'>
                        <img
                          src='https://elonxcat.com/assets/images/main/sec3_1.png'
                          alt='elonxcat_ELONXCAT swap'
                        />
                      </div>
                      <div className='title'>
                        <h3>ELONXCAT swap</h3>
                      </div>
                      <br />
                      <div className='text'>
                        <p>{t('tiers.tier_txt_5')} </p>
                      </div>
                      <div className='bottom-function'>
                        <div className='status-bar'>
                          <div className='status-text'>
                            <p>{t('Ecosystem Percentage')}</p>
                          </div>
                          <div className='status-value'>
                            <span>35%</span>
                          </div>
                        </div>
                        <div className='status-bar'>
                          <div className='status-text'>
                            <p>Status</p>
                          </div>
                          <div className='status-value'>
                            <span>SOON</span>
                          </div>
                        </div>
                        <div className='bottom-btn'>
                          <button type='button'>SOON</button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='sec-item'>
                    <div className='box'>
                      <div className='icon'>
                        <img
                          src='https://elonxcat.com/assets/images/main/sec3_2.png'
                          alt='elonxcat_ELONXCAT QUEST GAME'
                        />
                      </div>
                      <br />
                      <div className='title'>
                        <h3>{t('ELONXCAT QUEST GAME')}</h3>
                      </div>
                      <br />
                      <div className='text'>
                        <p>{t('tiers.tier_txt_6')} </p>
                      </div>
                      <div className='bottom-function'>
                        <div className='status-bar'>
                          <div className='status-text'>
                            <p>{t('Ecosystem Percentage')}</p>
                          </div>
                          <div className='status-value'>
                            <span>25%</span>
                          </div>
                        </div>
                        <div className='status-bar'>
                          <div className='status-text'>
                            <p>Status</p>
                          </div>
                          <div className='status-value'>
                            <span>SOON</span>
                          </div>
                        </div>
                        <div className='bottom-btn'>
                          <button type='button'>SOON</button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='sec-item'>
                    <div className='box'>
                      <div className='icon'>
                        <img
                          src='https://elonxcat.com/assets/images/main/sec3_3.png'
                          alt='elonxcat_ELONXCAT NFT Marketplace'
                        />
                      </div>
                      <div className='title'>
                        <h3>ELONXCAT NFT Marketplace</h3>
                      </div>
                      <br />
                      <div className='text'>
                        <p>{t('tiers.tier_txt_7')} </p>
                      </div>
                      <div className='bottom-function'>
                        <div className='status-bar'>
                          <div className='status-text'>
                            <p>{t('Ecosystem Percentage')}</p>
                          </div>
                          <div className='status-value'>
                            <span>35%</span>
                          </div>
                        </div>
                        <div className='status-bar'>
                          <div className='status-text'>
                            <p>Status</p>
                          </div>
                          <div className='status-value'>
                            <span>SOON</span>
                          </div>
                        </div>
                        <div className='bottom-btn'>
                          <button type='button'>SOON</button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='sec-item'>
                    <div className='box'>
                      <div className='icon'>
                        <img
                          src='https://elonxcat.com/assets/images/main/sec3_4.png'
                          alt='elonxcat_ELONXCAT NFT Marketplace'
                        />
                      </div>
                      <div className='title'>
                        <h3>ELONXCAT AI</h3>
                      </div>
                      <br />
                      <div className='text'>
                        <p>{t('tiers.tier_txt_8')} </p>
                      </div>
                      <div className='bottom-function'>
                        <div className='status-bar'>
                          <div className='status-text'>
                            <p>{t('Ecosystem Percentage')}</p>
                          </div>
                          <div className='status-value'>
                            <span>15%</span>
                          </div>
                        </div>
                        <div className='status-bar'>
                          <div className='status-text'>
                            <p>Status</p>
                          </div>
                          <div className='status-value'>
                            <span>SOON</span>
                          </div>
                        </div>
                        <div className='bottom-btn'>
                          <button type='button'>SOON</button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div> */}
            <div id="section4">
              <div className="container">
                <div className="sec-title">
                  <h2>{t("ELONXCAT GET TOKEN GAME")}</h2>
                </div>
                <div className="play-elonxcat">
                  <div className="parent-wrap">
                    <div className="left-back" />
                    <div className="game-info">
                      <div className="text">
                        <p>{t("tiers.tier_txt_9")}</p>
                      </div>
                      <div className="bottom-btn">
                        <button type="button">
                          <h3>{t("ELONXCAT GAME PORTAL SOON")}</h3>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div id="section5">
              {/* <div class="left-back-image">
            <img src="https://elonxcat.com/assets/images/main/sec5_left_back.png" alt="elonxcat_back" />
        </div> */}
              <div className="container">
                <div className="sec-title">
                  <h2>
                    ELONXCAT
                    <br />
                    {t("NFT MARKET PLACE")}
                  </h2>
                </div>
                <div className="sec-wrap">
                  <div className="left-area">
                    <div className="info-row">
                      <div className="info-item">
                        <div className="icon">
                          <img
                            src="https://elonxcat.com/assets/images/main/sec5_1.png"
                            alt="elonxcat_Unique collections"
                          />
                        </div>
                        <div className="right-area">
                          <div className="title">
                            <h3>{t("Unique collections")}</h3>
                          </div>
                          <div className="text">
                            <p>{t("tiers.tier_txt_10")} </p>
                          </div>
                        </div>
                      </div>
                      <div className="info-item">
                        <div className="icon">
                          <img
                            src="https://elonxcat.com/assets/images/main/sec5_2.png"
                            alt="elonxcat_Launch at the same time as the project"
                          />
                        </div>
                        <div className="right-area">
                          <div className="title">
                            <h3>
                              {t("Launch at the same time as the project")}
                            </h3>
                          </div>
                          <div className="text">
                            <p>{t("tiers.tier_txt_11")} </p>
                          </div>
                        </div>
                      </div>
                      <div className="info-item">
                        <div className="icon">
                          <img
                            src="https://elonxcat.com/assets/images/main/sec5_3.png"
                            alt="elonxcat_NFT Box and Airdrop"
                          />
                        </div>
                        <div className="right-area">
                          <div className="title">
                            <h3>{t("NFT Box and Airdrop")}</h3>
                          </div>
                          <div className="text">
                            <p>{t("tiers.tier_txt_12")} </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="right-area">
                    <img
                      src="https://elonxcat.com/assets/images/main/sec5_right.png"
                      alt="elonxcat_NFT Market place"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div id="section6">
              <div className="container">
                <div className="sec-list">
                  <div className="sec-item">
                    <div className="box">
                      <div className="top-title">
                        <h3>
                          ELONXCAT
                          <br />
                          <small>NFT 1</small>
                        </h3>
                      </div>
                      <div className="icon">
                        <img
                          src="https://elonxcat.com/assets/images/main/sec6_1.png"
                          alt="ELONXCAT NFT"
                        />
                      </div>
                      {/* <div className='top-text'>
                        <p />
                      </div>
                      <div className='opacity-box'>
                        <ul>
                          <li>MEME</li>
                          <li>1000</li>
                        </ul>
                      </div>
                      <div className='middle-text'>
                        <p>
                          COLLECTION
                          <br />
                          <strong>card,meme</strong>
                        </p>
                      </div>
                      <div className='opacity-box'>
                        <ul>
                          <li>ROYALTIES</li>
                          <li>0%</li>
                        </ul>
                      </div>
                      <div className='bottom-text'>
                        <p>
                          NETWORK
                          <br />
                          Binance Smart Chain
                        </p>
                      </div> */}
                      <div className="discover-btn">
                        <button type="button">
                          <h4>{t("DISCOVER NFT")}</h4>
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="sec-item">
                    <div className="box">
                      <div className="top-title">
                        <h3>
                          ELONXCAT
                          <br />
                          <small>NFT 2</small>
                        </h3>
                      </div>
                      <div className="icon">
                        <img
                          src="https://elonxcat.com/assets/images/main/sec6_2.png"
                          alt="elonxcat_NFT"
                        />
                      </div>
                      {/* <div className='top-text'>
                        <p />
                      </div>
                      <div className='opacity-box'>
                        <ul>
                          <li>MEME</li>
                          <li>2500</li>
                        </ul>
                      </div>
                      <div className='middle-text'>
                        <p>
                          COLLECTION
                          <br />
                          <strong>card,meme</strong>
                        </p>
                      </div>
                      <div className='opacity-box'>
                        <ul>
                          <li>ROYALTIES</li>
                          <li>0%</li>
                        </ul>
                      </div>
                      <div className='bottom-text'>
                        <p>
                          NETWORK
                          <br />
                          Binance Smart Chain
                        </p>
                      </div> */}
                      <div className="discover-btn">
                        <button type="button">
                          <h4>{t("DISCOVER NFT")}</h4>
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="sec-item">
                    <div className="box">
                      <div className="top-title">
                        <h3>
                          ELONXCAT
                          <br />
                          <small>NFT 3</small>
                        </h3>
                      </div>
                      <div className="icon">
                        <img
                          src="https://elonxcat.com/assets/images/main/sec6_3.png"
                          alt="elonxcat_NFT"
                        />
                      </div>
                      {/* <div className='top-text'>
                        <p />
                      </div>
                      <div className='opacity-box'>
                        <ul>
                          <li>MEME</li>
                          <li>5800</li>
                        </ul>
                      </div>
                      <div className='middle-text'>
                        <p>
                          COLLECTION
                          <br />
                          <strong>card,meme</strong>
                        </p>
                      </div>
                      <div className='opacity-box'>
                        <ul>
                          <li>ROYALTIES</li>
                          <li>0%</li>
                        </ul>
                      </div>
                      <div className='bottom-text'>
                        <p>
                          NETWORK
                          <br />
                          Binance Smart Chain
                        </p>
                      </div> */}
                      <div className="discover-btn">
                        <button type="button">
                          <h4>{t("DISCOVER NFT")}</h4>
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="sec-item">
                    <div className="box">
                      <div className="top-title">
                        <h3>
                          ELONXCAT
                          <br />
                          <small>NFT 4</small>
                        </h3>
                      </div>
                      <div className="icon">
                        <img
                          src="https://elonxcat.com/assets/images/main/sec6_4.png"
                          alt="elonxcat_NFT"
                        />
                      </div>
                      {/* <div className='top-text'>
                        <p />
                      </div>
                      <div className='opacity-box'>
                        <ul>
                          <li>MEME</li>
                          <li>1000</li>
                        </ul>
                      </div>
                      <div className='middle-text'>
                        <p>
                          COLLECTION
                          <br />
                          <strong>card,meme</strong>
                        </p>
                      </div>
                      <div className='opacity-box'>
                        <ul>
                          <li>ROYALTIES</li>
                          <li>0%</li>
                        </ul>
                      </div>
                      <div className='bottom-text'>
                        <p>
                          NETWORK
                          <br />
                          Binance Smart Chain
                        </p>
                      </div> */}
                      <div className="discover-btn">
                        <button type="button">
                          <h4>{t("DISCOVER NFT")}</h4>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* <div class="sec7-back-image">
        <img src="https://elonxcat.com/assets/images/main/sec7_back.png" alt="elonxcat_BACKGROUND" />
        </div> */}
            <div id="section7">
              <div className="container">
                <div className="sec-title">
                  <h2>ELONXCAT AI</h2>
                </div>
                <div className="sec-wrap">
                  <div className="left-area">
                    <img
                      src="https://elonxcat.com/assets/images/main/sec7_left.png"
                      alt="elonxcat_AI"
                    />
                  </div>
                  <div className="right-area">
                    <div className="sec-list">
                      <div className="sec-item">
                        <div className="list-wrap">
                          <div className="icon">
                            <img
                              src="https://elonxcat.com/assets/images/main/sec7_1.png"
                              alt="elonxcat_Powered"
                            />
                          </div>
                          <div className="text-area">
                            <h3>Powered</h3>
                            <p>{t("tiers.tier_txt_13")}</p>
                          </div>
                        </div>
                      </div>
                      <div className="sec-item">
                        <div className="list-wrap">
                          <div className="icon">
                            <img
                              src="https://elonxcat.com/assets/images/main/sec7_2.png"
                              alt="elonxcat_AI-Powerde Content"
                            />
                          </div>
                          <div className="text-area">
                            <h3>{t("AI Image Generator")}</h3>
                            <p>{t("tiers.tier_txt_14")}</p>
                          </div>
                        </div>
                      </div>
                      <div className="sec-item">
                        <div className="list-wrap">
                          <div className="icon">
                            <img
                              src="https://elonxcat.com/assets/images/main/sec7_3.png"
                              alt="elonxcat_AI-Powerde Content"
                            />
                          </div>
                          <div className="text-area">
                            <h3>{t("AI Chat Bot")}</h3>
                            <p>{t("tiers.tier_txt_15")}</p>
                          </div>
                        </div>
                      </div>
                      <div className="sec-item">
                        <div className="list-wrap">
                          <div className="icon">
                            <img
                              src="https://elonxcat.com/assets/images/main/sec7_4.png"
                              alt="elonxcat_AI-Powerde Content"
                            />
                          </div>
                          <div className="text-area">
                            <h3>{t("AI Code")}</h3>
                            <p>{t("tiers.tier_txt_16")} </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div id="section8">
              <div className="container">
                <div className="sec-wrap">
                  <div className="left-area">
                    <img
                      src="https://elonxcat.com/assets/images/main/sec8_left.png"
                      alt="elonxcat_coin swap"
                    />
                  </div>
                  <div className="right-area">
                    <div className="sec-title">
                      <h2>
                        <span className="left">ELONXCAT</span>
                        <span className="right">{t("COIN SWAP")}</span>
                      </h2>
                    </div>
                    <div className="text-area">
                      <p>
                        {t("tiers.tier_txt_17")} <br />
                        <br />
                        {t("tiers.tier_txt_18")}
                      </p>
                    </div>
                    <div className="logo-list">
                      <div className="logo-item">
                        <img
                          src="https://elonxcat.com/assets/images/main/sec7_logo1.png"
                          alt="elonxcat_logo 1"
                        />
                      </div>
                      <div className="logo-item">
                        <img
                          src={ethereumLogo}
                          alt="elonxcat_logo 2"
                          style={{ width: "130px" }}
                        />
                      </div>
                      <div className="logo-item">
                        <img
                          src="https://elonxcat.com/assets/images/main/sec7_logo3.png"
                          alt="elonxcat_logo 3"
                        />
                      </div>
                      <div className="logo-item">
                        <img
                          src="https://elonxcat.com/assets/images/main/sec7_logo4.png"
                          alt="elonxcat_logo 4"
                        />
                      </div>
                      <div className="logo-item">
                        <img
                          src={solanaLogo}
                          alt="elonxcat_logo 5"
                          style={{ width: "130px" }}
                        />
                      </div>
                      <div className="logo-item">
                        <img
                          src={tronLogo}
                          alt="elonxcat_logo 6"
                          style={{ width: "120px" }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div id="section9">
              <div className="container">
                <div className="sec-title">
                  <h2>{t("TOKENOMICS")}</h2>
                </div>
                <div className="sec-wrap">
                  <div className="left-area">
                    <div className="chart-area">
                      {/* <canvas className="token_posleft" id="token_chart" ref={tokenPosLeftRef}/> */}
                    </div>
                  </div>
                  <div className="right-area">
                    <div className="area-parent">
                      <div className="top-text">
                        <h3>{t("ELONXCAT ALLOCAT STASTISTICS")}</h3>
                        <p>
                          {t("TOTAL SUPPLY")} : <span>500,000,000,000,000</span>
                        </p>
                        <p>
                          PUBLIC SALE : <span>PRESALE Tier Level</span>
                        </p>
                      </div>
                      <table>
                        <thead>
                          <tr>
                            <th>fund</th>
                            <th>assignment</th>
                            <th>token</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>{t("PRESALE ALLOCATION")}</td>
                            <td>20%</td>
                            <td>100,000,000,000,000</td>
                          </tr>
                          <tr>
                            <td>{t("AIRDROP")}</td>
                            <td>10%</td>
                            <td>50,000,000,000,000</td>
                          </tr>
                          <tr>
                            <td>{t("PROJECTFUNDS")}</td>
                            <td>25%</td>
                            <td>125,000,000,000,000</td>
                          </tr>
                          <tr>
                            <td>{t("LIQUIDITY")}</td>
                            <td>10%</td>
                            <td>50,000,000,000,000</td>
                          </tr>
                          <tr>
                            <td>{t("MARKETING")}</td>
                            <td>20%</td>
                            <td>100,000,000,000,000</td>
                          </tr>
                          <tr>
                            <td>{t("EXCHANGES")}</td>
                            <td>5%</td>
                            <td>25,000,000,000,000</td>
                          </tr>
                          <tr>
                            <td>{t("ECOSYSTEM FUNDS")}</td>
                            <td>10%</td>
                            <td>50,000,000,000,000</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* <div class="sec10-back-image">
        <img src="https://elonxcat.com/assets/images/main/sec10_back.png" alt="elonxcat_BACKGROUND" />
        </div> */}
            <div id="section10">
              <div className="sec-title">
                <h2>{t("WELCOME TO ELONXCAT STAKING")}</h2>
                <p>{t("ELONXCAT STAKING SERVICE IS COMING SOON")}</p>
              </div>
              <div className="container">
                <div className="sub-info">
                  <div className="left-area">
                    <p>{t("tiers.tier_txt_19")} </p>
                  </div>
                  <div className="right-area" style={{ marginLeft: "20px" }}>
                    <div className="token-btn">
                      <button type="button">
                        {t("WITHDRAW STAKED TOKENS")}
                      </button>
                    </div>
                  </div>
                </div>
                <div className="container-3">
                  <div className="token-status-row">
                    <div className="status-item">
                      <div className="top-area">
                        <p>
                          <b>{t("STAKED BALANCE")}</b>
                          <br />0 $EXC
                        </p>
                      </div>
                      <div className="bottom-area">
                        <p>
                          <b>{t("YOUR STAKEABLE")}</b>
                          <br />0 $EXC
                        </p>
                      </div>
                    </div>
                    <div className="status-item">
                      <div className="top-area">
                        <p>
                          % <b>OF POOL</b>
                          <br />
                          0%
                        </p>
                      </div>
                      <div className="bottom-area">
                        <p>
                          <b>{t("TOTAL STAKED")}</b>
                          <br />0 $EXC
                        </p>
                      </div>
                    </div>
                    <div className="status-item">
                      <div className="top-area">
                        <p>
                          <b>{t("ESTIMATED REWARDS")}</b>
                          <br />
                          74% P/A
                        </p>
                      </div>
                      <div className="bottom-area">
                        <p>
                          &gt; {t("Rewards rate is dynamic")}
                          <br />
                          &gt; {t("Monthly")} = Rewards % / 12
                          <br />
                          &gt; {t("Daily")} = Rewards % / 365
                        </p>
                      </div>
                    </div>
                    <div className="status-item">
                      <div className="top-area">
                        <p>
                          <b>{t("CURRENT REWARDS")}</b> 708.72 per ETH BLOCK
                        </p>
                      </div>
                    </div>
                    <div className="status-item">
                      <div className="top-area">
                        <p>
                          <b>
                            {t("TOTAL REWARDS")}
                            <br />0 $<b>ELONXCAT</b>
                          </b>
                        </p>
                      </div>
                      <div className="bottom-area">
                        <button type="button" className="reward-btn">
                          {t("CLAIM REWARDS")}{" "}
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="total-supply">
                    <div className="left-area">
                      <div className="total-supply-parent">
                        <div className="title">
                          <h3>{t("TOTAL SUPPLY")}</h3>
                        </div>
                        <BarChart />
                      </div>
                      <div className="staking-powered-by">
                        <p>
                          STAKING POWERED BY
                          <img
                            src="https://elonxcat.com/assets/images/icons/web_payments.png"
                            alt="elonxcat_WEB3PAYMENTS"
                          />
                        </p>
                      </div>
                    </div>
                    <div className="right-area">
                      <img
                        src="https://elonxcat.com/assets/images/main/sec10_right.png"
                        alt="elonxcat_TOTAL SUPPLY"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div id="section11">
              <div className="sec-title">
                <h2>{t("OUR PARTNERS and BACKERS")}</h2>
              </div>
              <div className="container-3">
                <div className="brand-parent">
                  <div className="brand-list">
                    <div className="brand-item">
                      <img
                        src="https://elonxcat.com/assets/images/main/sec11_1.png"
                        alt="elonxcat_coinsult"
                        onClick={() => {
                          window.open("https://coinsult.net/", "_blank");
                        }}
                      />
                    </div>
                    <div className="brand-item">
                      <img
                        src="https://elonxcat.com/assets/images/main/sec11_2.png"
                        alt="elonxcat_bitmart"
                        onClick={() => {
                          window.open("https://www.bitmart.com/", "_blank");
                        }}
                      />
                    </div>
                    <div className="brand-item">
                      <img
                        src="https://elonxcat.com/assets/images/main/sec11_3.png"
                        alt="elonxcat_coinbabber"
                        onClick={() => {
                          window.open("https://www.coingabbar.com/", "_blank");
                        }}
                      />
                    </div>
                    <div className="brand-item">
                      <img
                        src="https://elonxcat.com/assets/images/main/sec11_4.png"
                        alt="elonxcat_coinmarketcap"
                        onClick={() => {
                          window.open("https://coinmarketcap.com/", "_blank");
                        }}
                      />
                    </div>
                    <div className="brand-item">
                      <img
                        src="https://elonxcat.com/assets/images/main/sec11_5.png"
                        alt="elonxcat_crypto"
                        onClick={() => {
                          window.open("https://cryptopromo.io/", "_blank");
                        }}
                      />
                    </div>
                    <div className="brand-item">
                      <img
                        src="https://elonxcat.com/assets/images/main/sec11_6.png"
                        alt="elonxcat_cryptodex"
                        onClick={() => {
                          window.open("https://www.coingabbar.com/", "_blank");
                        }}
                      />
                    </div>
                    <div className="brand-item">
                      <img
                        src="https://elonxcat.com/assets/images/main/sec11_7.png"
                        alt="elonxcat_dexscreener"
                        onClick={() => {
                          window.open("https://dexscreener.com/", "_blank");
                        }}
                      />
                    </div>
                    <div className="brand-item">
                      <img
                        src="https://elonxcat.com/assets/images/main/sec11_8.png"
                        alt="elonxcat_dextools"
                        onClick={() => {
                          window.open("https://www.dextools.io/", "_blank");
                        }}
                      />
                    </div>
                    <div className="brand-item">
                      <img
                        src="https://elonxcat.com/assets/images/main/sec11_9.png"
                        alt="elonxcat_p2b"
                        onClick={() => {
                          window.open("https://p2pb2b.com/", "_blank");
                        }}
                      />
                    </div>
                    <div className="brand-item">
                      <img
                        src="https://elonxcat.com/assets/images/main/sec11_10.png"
                        alt="elonxcat_pinksale"
                        onClick={() => {
                          window.open(
                            "https://www.pinksale.finance/",
                            "_blank"
                          );
                        }}
                      />
                    </div>
                    <div className="brand-item">
                      <img
                        src="https://elonxcat.com/assets/images/main/sec11_11.png"
                        alt="elonxcat_cryptotown"
                        onClick={() => {
                          window.open("https://www.coingabbar.com/", "_blank");
                        }}
                      />
                      <img
                        src="https://elonxcat.com/assets/images/main/sec11_12.png"
                        alt="elonxcat_crypto"
                        onClick={() => {
                          window.open("https://www.coingabbar.com/", "_blank");
                        }}
                      />
                    </div>
                    <div className="brand-item">
                      <img
                        src="https://elonxcat.com/assets/images/main/sec11_13.png"
                        alt="elonxcat_binance"
                        onClick={() => {
                          window.open("https://www.binance.com/", "_blank");
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div id="section12">
              <div className="sec-title">
                <h2>FEATURED IN</h2>
              </div>
              <div className="container-3">
                <div className="brand-parent">
                  <div className="brand-list">
                    <div className="brand-item">
                      <img
                        src="https://elonxcat.com/assets/images/main/sec12_1.png"
                        alt="elonxcat_cointelegraph"
                        onClick={() => {
                          window.open("https://cointelegraph.com/", "_blank");
                        }}
                      />
                    </div>
                    <div className="brand-item">
                      <img
                        src="https://elonxcat.com/assets/images/main/sec12_2.png"
                        alt="elonxcat_techopedia"
                        onClick={() => {
                          window.open("https://www.techopedia.com/", "_blank");
                        }}
                      />
                    </div>
                    <div className="brand-item">
                      <img
                        src="https://elonxcat.com/assets/images/main/sec12_3.png"
                        alt="elonxcat_cryptonews"
                        onClick={() => {
                          window.open("https://cryptonews.com/", "_blank");
                        }}
                      />
                    </div>
                    <div className="brand-item">
                      <img
                        src="https://elonxcat.com/assets/images/main/sec12_4.png"
                        alt="elonxcat_bitcoinist"
                        onClick={() => {
                          window.open("https://bitcoinist.com/", "_blank");
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div id="section14">
              <div className="sec-title">
                <h2>{t("ELONXCAT TEAM")}</h2>
              </div>
              <div className="container-3">
                <div className="sec-wrap">
                  <div className="sec-item">
                    <div className="char-image">
                      <img
                        src="https://elonxcat.com/assets/images/main/sec14_1.png"
                        alt="elonxcat_JADEN"
                      />
                    </div>
                    <div className="card-text">
                      <p>
                        <h3>Jaden</h3>
                        {t("Founder")} , CEO
                      </p>
                    </div>
                  </div>
                  <div className="sec-item">
                    <div className="char-image">
                      <img
                        src="https://elonxcat.com/assets/images/main/sec14_2.png"
                        alt="elonxcat_DREW"
                      />
                    </div>
                    <div className="card-text">
                      <p>
                        <h3>DREW</h3>
                        CTO
                      </p>
                    </div>
                  </div>
                  <div className="sec-item">
                    <div className="char-image">
                      <img
                        src="https://elonxcat.com/assets/images/main/sec14_3.png"
                        alt="elonxcat_LIME LEAF"
                      />
                    </div>
                    <div className="card-text">
                      <p>
                        <h3>LIME LEAF</h3>
                        COO
                      </p>
                    </div>
                  </div>
                  <div className="sec-item">
                    <div className="char-image">
                      <img
                        src="https://elonxcat.com/assets/images/main/sec14_4.png"
                        alt="elonxcat_ROWAN"
                      />
                    </div>
                    <div className="card-text">
                      <p>
                        <h3>ROWAN</h3>
                        CDO
                      </p>
                    </div>
                  </div>
                  <div className="sec-item">
                    <div className="char-image">
                      <img
                        src="https://elonxcat.com/assets/images/main/sec14_5.png"
                        alt="elonxcat_JADEN"
                      />
                    </div>
                    <div className="card-text">
                      <p>
                        <h3>SAGE</h3>
                        Sage
                      </p>
                    </div>
                  </div>
                  <div className="sec-item">
                    <div className="char-image">
                      <img
                        src="https://elonxcat.com/assets/images/main/sec14_6.png"
                        alt="elonxcat_JADEN"
                      />
                    </div>
                    <div className="card-text">
                      <p>
                        <h3>CAMERON</h3>
                        {t("Creative Director")}
                      </p>
                    </div>
                  </div>
                  <div className="sec-item">
                    <div className="char-image">
                      <img
                        src="https://elonxcat.com/assets/images/main/sec14_7.png"
                        alt="elonxcat_JADEN"
                      />
                    </div>
                    <div className="card-text">
                      <p>
                        <h3>PEYTON</h3>
                        {t("Community manger")}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* <div id="section15">
              <div className="left-image">
                <img
                  src="https://elonxcat.com/assets/images/main/sec15_left.png"
                  alt="elonxcat_left character"
                />
              </div>
              <div className="right-image">
                <img
                  src="https://elonxcat.com/assets/images/main/sec15_right.png"
                  alt="elonxcat_right character"
                />
              </div>
              <div className="sec-title">
                <h2>{t("HOW TO BUY")}</h2>
              </div>
              <div className="container-3">
                <div className="step-row">
                  <div className="step-item">
                    <div className="step-number">
                      <h3>STEP 1</h3>
                    </div>
                    <div className="step-content">
                      <div className="text">
                        <h4>{t("CONNECT YOUR WALLET")}</h4>
                        <p>{t("tiers.tier_txt_20")}</p>
                      </div>
                    </div>
                  </div>
                  <div className="step-item">
                    <div className="step-number">
                      <h3>STEP 2</h3>
                    </div>
                    <div className="step-content">
                      <div className="text">
                        <h4>{t("ENTER PRESALE")}</h4>
                        <p>{t("tiers.tier_txt_21")} </p>
                      </div>
                    </div>
                  </div>
                  <div className="step-item">
                    <div className="step-number">
                      <h3>STEP 3</h3>
                    </div>
                    <div className="step-content">
                      <div className="text">
                        <h4>{t("CLAIM ELONXCAT")}</h4>
                        <p>{t("tiers.tier_txt_22")}y </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div> */}
            {/* <div class="sec16-back">
        <img src="https://elonxcat.com/assets/images/main/sec16_back.png" alt="elonxcat_background" />
        </div> */}
            <div id="section16">
              <div className="sec-title">
                <h2>ELONXCAT {t("ROAD MAP")}</h2>
              </div>
              <div className="container-4">
                <div className="sec-wrap">
                  <div className="sec-item sec-item-1">
                    <div className="box">
                      <div className="title">
                        <h3>PHASE 1</h3>
                        <p>{t("tiers.tier_txt_23")} </p>
                      </div>
                      <div className="text">
                        <p>{t("tiers.tier_txt_24")} </p>
                      </div>
                    </div>
                  </div>
                  <div className="sec-item sec-item-2">
                    <div className="box">
                      <div className="title">
                        <h3>PHASE 2</h3>
                        <p>{t("DEVELOPMENT")}</p>
                      </div>
                      <div className="text">
                        <p>{t("tiers.tier_txt_25")} </p>
                      </div>
                    </div>
                  </div>
                  <div className="sec-item sec-item-3">
                    <div className="box">
                      <div className="title">
                        <h3>PHASE 3</h3>
                        <p>{t("EXPANSION")}</p>
                      </div>
                      <div className="text">
                        <p>{t("tiers.tier_txt_26")} </p>
                      </div>
                    </div>
                  </div>
                  <div className="sec-item sec-item-4">
                    <div className="box">
                      <div className="title">
                        <h3>PHASE 4</h3>
                        <p>{t("MEME DOMINATION")}</p>
                      </div>
                      <div className="text">
                        <p>{t("tiers.tier_txt_27")} </p>
                      </div>
                    </div>
                  </div>
                  <div className="sec-item sec-item-5">
                    <div className="box">
                      <div className="title">
                        <h3>PHASE 5</h3>
                        <p />
                      </div>
                      <div className="text">
                        <p>{t("tiers.tier_txt_28")}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div id="section17">
              <div className="sec-title">
                <h2>{t("FREQUENTLY ASKED QUESTIONS")}</h2>
              </div>
              <div className="container-3">
                <div className="question-wrap">
                  {faqData.map((faq, index) => (
                    <FaqItem
                      key={index}
                      index={index}
                      question={faq.question}
                      answer={faq.answer}
                      isOpen={openQuestions[index]}
                      onToggle={toggleQuestion}
                    />
                  ))}
                </div>
              </div>
            </div>
            <div id="section18">
              <div className="sec-title">
                <h2>{t("STAY CONNECTED")}</h2>
              </div>
              <div className="social-list">
                <div className="social-item">
                  <div className="icon">
                    <a
                      href="https://x.com/elonxcat"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <BsTwitterX
                        style={{
                          width: "44px",
                          height: "44px",
                        }}
                      />
                    </a>
                  </div>
                  <div className="text">
                    <h3>TWITTER</h3>
                  </div>
                </div>
                <div className="social-item">
                  <div className="icon">
                    <a
                      href="https://t.me/ELONXCAT_PORTAL"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <BiLogoTelegram
                        style={{
                          width: "48px",
                          height: "48px",
                        }}
                      />
                    </a>
                  </div>
                  <div className="text">
                    <h3>TELEGRAM</h3>
                  </div>
                </div>
                <div className="social-item">
                  <div className="icon">
                    <a
                      href="https://www.youtube.com/@ELONXCAT"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <RiYoutubeFill
                        style={{
                          width: "50px",
                          height: "50px",
                        }}
                      />
                    </a>
                  </div>
                  <div className="text">
                    <h3>YOUTUBE</h3>
                  </div>
                </div>
                <div className="social-item">
                  <div className="icon">
                    <a
                      href="https://www.instagram.com/elonxcat"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <RiInstagramFill
                        style={{
                          width: "48px",
                          height: "48px",
                        }}
                      />
                    </a>
                  </div>
                  <div className="text">
                    <h3>INSTAGRAM</h3>
                  </div>
                </div>

                <div className="social-item">
                  <div className="icon">
                    <a
                      href="https://elonxcat.world/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img
                        src={netWorld}
                        alt="elonxcat_DREW"
                        style={{
                          width: "40px",
                          height: "40px",
                        }}
                      />
                    </a>
                  </div>
                  <div className="text">
                    <h3>NFT WORLD</h3>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <footer>
          <div className="container">
            <div className="footer-wrap">
              <div className="ft-top">
                <div className="sitemap-area">
                  <ul>
                    <li>
                      <a href="#">{t("ABOUT")}</a>
                    </li>
                    <li>
                      <a
                        href="/ELONCAT Privacy Policy.pdf"
                        target="_blank"
                        rel="noreferrer noopenner"
                      >
                        {t("PRIVACY POLICY")}
                      </a>
                    </li>
                    <li>
                      <a href="#">{t("HOW TO BUY")}</a>
                    </li>
                    <li>
                      <a
                        href="/ELONCAT Cookies Policy.pdf"
                        target="_blank"
                        rel="noreferrer noopenner"
                      >
                        COOKIES
                      </a>
                    </li>
                    <li>
                      <a href="#">{t("ROADMAP")}</a>
                    </li>
                    <li>
                      <a
                        href="/ELONCAT TERMS OF SERVICE.pdf"
                        target="_blank"
                        rel="noreferrer noopenner"
                      >
                        {t("TERMS and CONDITIONS")}
                      </a>
                    </li>
                    <li>
                      <a href="#">{t("FAQ")}</a>
                    </li>
                  </ul>
                </div>
                <div className="ft-logo">
                  <img
                    src="https://elonxcat.com/assets/images/ft-logo.png"
                    alt="elonxcat"
                  />
                </div>
                <div className="company-info">
                  <p>
                    {t("tiers.tier_txt_39")}
                    <br />
                    <a
                      href="mailto:contact@elonxcat.com"
                      className="__cf_email__"
                    >
                      contact@elonxcat.com
                    </a>
                  </p>
                </div>
              </div>
              <div className="sponsored-text">
                <p>
                  SPONSORED BY COINNEWS. GET THE LATEST BITCOIN &amp; CRYPTO
                  NEWS
                </p>
              </div>
              <div className="copyright">
                <p>
                  Copyright 2024 ELONXCAT. All Rights Reserved.
                  <br />
                  <br />
                  <p
                    dangerouslySetInnerHTML={{ __html: t("tiers.tier_txt_40") }}
                  ></p>{" "}
                </p>
              </div>
            </div>
          </div>
        </footer>
      </div>
    </div>
  );
};

export default ElonXCat;
