export default {
  ABOUT: '介绍',
  TOKENOMICS: '代币经济学',
  STAKING: '质押',
  'HOW TO BUY': '如何购买',
  ROADMAP: '路线图',
  FAQ: '常见问题解答',
  'BUY NOW': '立即购买',
  'ELONXCAT is the real': 'ELONXCAT 是真实的',
  ELONMUSK: '埃隆·马斯克',
  'SpaceX and X': 'SpaceX 和',
  'meme project': '模因项目',
  'Advanced Web3 Ecosystem': '先进的Web3生态系统',
  'Potential of project': '项目潜力',
  'Market cap Target': '目标市值',
  Investors: '投资者',
  'WHITE PAPER': '白书',
  AUDIT: '谢谢',
  'Presale will start soon. Join the Presale': '预售即将开始。参与预售',
  'TOKEN DISTRIBUTION DATE': '代币分发日期',
  Raised: '筹集金额',
  'ELONXCAT sold': 'ELONXCAT销量',
  BUY: '购买',
  'MULTI BRIDGE UTILITY': '多桥实用程序',
  'Total Supply': '总供应量',
  'ELONXCAT ACHIEVEMENTS': 'ELONXCAT 性能',
  'Ecosystem Percentage': '生态系统比例',
  'ELONXCAT GET TOKEN GAME': 'ELONXCAT 获取代币游戏',
  'ELONXCAT GAME PORTAL SOON': 'ELONXCAT 游戏门户即将推出',
  'NFT MARKET PLACE': 'NFT 市场',
  'Unique collections': '独特的收藏',
  'Launch at the same time as the project': '与项目同时发布',
  'NFT Box and Airdrop': 'NFT盒子和空投',
  'DISCOVER NFT': '探索 NFT',
  'AI-Powerde Content': '基于人工智能的内容',
  'AI Image Generator': '人工智能图像生成器',
  'AI Chat Bot': '人工智能聊天机器人',
  'AI Code': '人工智能代码',
  'COIN SWAP': '硬币交换',
  'ELONXCAT ALLOCAT STASTISTICS': 'ELONXCAT分配统计',
  'ELONXCAT STAKING SERVICE IS COMING SOON': 'ELONXCAT 质押服务即将推出',
  'WELCOME TO ELONXCAT STAKING': '欢迎来到 ELONXCAT 质押',
  'WITHDRAW STAKED TOKENS': '提取质押代币',
  'STAKED BALANCE': '质押余额',
  'YOUR STAKEABLE': '可用于质押的余额',
  'TOTAL STAKED': '总质押',
  'ESTIMATED REWARDS': '预期回报',
  'Rewards rate is dynamic': '奖励比例是动态的',
  Monthly: '每月',
  Daily: '一眼',
  'CURRENT REWARDS': '当前奖励',
  'TOTAL REWARDS': '总奖励',
  'CLAIM REWARDS': '赔偿要求',
  'TOTAL SUPPLY': '总供应量',
  'OUR PARTNERS and BACKERS': '我们的合作伙伴和赞助商',
  'ELONXCAT TEAM': 'ELONXCAT团队',
  Founder: '创始人',
  'Creative Director': '创意总监',
  'Community manger': '社区经理',
  'CONNECT YOUR WALLET': '钱包连接',
  'ENTER PRESALE': '预售参与',
  'CLAIM ELONXCAT': 'ELONXCAT 计费',
  'ROAD MAP': '路线图',
  DEVELOPMENT: '发展',
  'MEME DOMINATION': '模因捐赠',
  'FREQUENTLY ASKED QUESTIONS': '常见问题解答',
  'What is a presale': '什么是预售？',
  'What is ELONXCAT': '什么是 ELONXCAT？',
  'How do I participate in the presale': '我如何参与预售？',
  'When do I receive my presale tokens': '我什么时候可以收到预售代币？',
  'What is ELONXCAT staking': '什么是 ELONXCAT 质押？',
  'How do I stake my tokens': '我如何质押我的代币？',
  'I staked my tokens during presale':
    '我在预售期间质押了代币，我什么时候可以领取它们？',
  'STAY CONNECTED': '保持联系',
  'PRIVACY POLICY': '隐私政策',
  'HOW TO BUY': '如何购买',
  ABOUT: '介绍',
  'TERMS and CONDITIONS': '使用条款',
  ROADMAP: '路线图',
  'PRESALE ALLOCATION': '预售分配',
  AIRDROP: '空投',
  PROJECTFUNDS: '项目资助',
  LIQUIDITY: '流动性',
  MARKETING: '营销',
  EXCHANGES: '交换',
  'ECOSYSTEM FUNDS': '生态系统基金',
  EXPANSION: '扩张',
  'HELP CENTER': '帮助中心',
  "If it doesn't work well on mobile": '如果在手机上无法正常运行',
  'please try using PC': '请尝试使用电脑',
  'twitter-announce1': '您可以在X（Twitter）上查看实时公告',
  'twitter-announce2': '除非您关注，否则无法查看完整帖子。',
  'twitter-announce3': '请务必关注！',

  texts: {
    txt_1:
      '我们自己的技术 X 支持这种多链。 X支持链迁移功能，允许ELONXCAT持有者在领先的加密货币链之间自由移动。',
    txt_2:
      'ELONXCAT 是一种建立在跨 BNB 链、以太坊、Polygon、Solana 和 Tron 的多链网络上的 meme 代币。',
    txt_3: 'ELONXCAT 致力于团结加密货币模因社区，成为多链实用程序的先驱。',
    txt_4:
      '我们的目标是让我们的名字闪耀在每个 DEX、每个 CEX 以及其他任何地方。',
    txt_5: '利用',
    txt_6: 'X未来将继续扩展这些多链。',
  },
  'PRESALE TIERS': '预售阶段',
  tiers: {
    tier_txt_1: '初始预售价格为每座位 0.0000009 美元，20 天后将变为 Tier2。',
    tier_txt_2:
      '此层级的价格从预售开始设定为 0.000001 美元，20 天后将变为 Tier3。',
    tier_txt_3:
      '此层级的价格从预售开始设定为 0.000005 美元，20 天后将变为最终价格。',
    tier_txt_4:
      '此层级的预售价格为 0.000009 美元，将以这个价格结束 ELONXCAT 预售。',
    tier_txt_5:
      'ELONXCAT 旨在成为 BSC 上最好的 DEX，具有代币互换、流动性池和流动性挖矿等功能。',
    tier_txt_6:
      'ELONXCAT Quest 将游戏和加密货币结合在一起，让您有机会通过区块链世界中的任务和谜题赚取 Lope 代币。',
    tier_txt_7:
      'ELONXCAT NFT 项目将数字艺术与 ELONXCAT memecoin 相结合，提供独特、实用性丰富的收藏品，以增强社区参与度。',
    tier_txt_8:
      'ELONXCAT 通过集成用于聊天、交易技巧、表情包创建等的人工智能，将金融和创造力结合在一个用户友好的平台中。',
    tier_txt_9:
      'ELONXCAT GET TOKEN GAME 是一款区块链游戏。ELONXCAT 生态系统将游戏与代币经济相结合，允许玩家完成各种任务并参与社交活动以赚取代币。这款游戏的目的是展示我们的区块链技术，同时通过积极参与游戏，让您更熟悉我们的项目并赚取收益。',
    tier_txt_10:
      'ELONXCAT Coin NFT 市场拥有独特的收藏品，具有特殊和独特的功能。',
    tier_txt_11:
      'ELONXCAT Coin NFT 将与该项目一起在所有全球市场以及该项目自己的市场上上市。',
    tier_txt_12:
      '该市场包括一个大型空投计划，该计划将在 NFT 盒子和代币预售期间激活。',
    tier_txt_13:
      '创建 SEO 优化的博客、销售电子邮件等...节省时间并获得出色的结果。最后，有一个真正可用的创作工具。',
    tier_txt_14:
      '想象你的梦想。创建带有文本的图像。使用我们的 AI 图像生成器立即为您的艺术创新创建令人惊叹的图像。',
    tier_txt_15:
      '只需选择您的助手并与友好的聊天机器人聊天即可产生想法、改进您的内容，有时甚至带来笑声。',
    tier_txt_16:
      '准备好以闪电般的速度编写代码了吗？使用 AI 代码生成器轻松编码：为您的编程需求提供即时解决方案。',
    tier_txt_17:
      'ElonXCat Swap 旨在成为币安智能链（BSC）上的领先去中心化交易所（DEX）平台。',
    tier_txt_18:
      '针对 ElonXCat 迷因币及其生态系统量身定制，ElonXCat Swap 促进无缝的代币交换，提供流动性池，并引入创新功能，如收益耕作和质押。这些功能旨在增强流动性，确保公平交易，并促进 ElonXCat 社区的整体增长。',
    tier_txt_19:
      'ELONXCAT 代币奖励将在两年内以每个以太坊区块 708.72 $ELONXCAT 的价格进行分配，一旦激活即可领取。',
    tier_txt_20: '使用 Metamask 或 Trust Wallet 在几秒钟内连接您的钱包。',
    tier_txt_21:
      '决定您要购买多少 $ELONXCAT。  您可以使用 USDT 与 BNB、ETH、USDT 一起购买。',
    tier_txt_22:
      '预售结束后，您可以通过您购买时使用的钱包和链来领取您的 $ELONXCAT 代币。',
    tier_txt_23: 'ELONMUSK 的诞生 &',
    tier_txt_24: '社区建设 代币生成 社交渠道 诞生',
    tier_txt_25: 'KYC & 审计 营销泵 预售开始',
    tier_txt_26:
      '开始营销 CoinMarketCap 申请 申请CoinGecko ElonXCAT NFT更新 ElonXCAT游戏开发 质押更新',
    tier_txt_27: '首个DEX上线 DEXTools更新 ElonXCAT AI更新 自动刻录',
    tier_txt_28: '持续营销 社区激励 与其他平台合作 目标持有量：100万+',
    tier_txt_29:
      '预售为投资者提供了在正式推出之前购买新代币或加密货币的独特机会。这个早期访问阶段使我们能够从一开始就充分利用该项目的潜力。',
    tier_txt_30:
      'ELONXCAT 是一种真正先进的多链模因币，实际上是 ELONMUSK &先进的 Web3 生态系统：质押 |去中心化交易所 |人工智能 |游戏| NFT 市场。',
    tier_txt_31:
      '您可以参与BNB链上的预售。将 Best Wallet 或其他钱包连接到上面的预售小部件，并使用 BNB、ETH 或 USDT 储备 $ELONXCAT 代币。',
    tier_txt_32:
      '预售购买完成后，您可以在首次 DEX 上市之前使用同一个钱包领取您的代币。账单和上市日期将在我们的社交媒体帐户上公布。',
    tier_txt_33:
      '质押是将代币发送到智能合约以将其锁定一段时间的行为。这使您可以随着时间的推移以动态比率赚取更多 $ELONXCAT 代币。 ELONXCAT 质押即将在 BNB 网络上推出。欲了解更多信息，请点击导航栏中的“质押”。',
    tier_txt_34:
      '要质押 $ELONXCAT，请访问此处：https://ELONXCAT/en/stake 只有在 BNB 上购买 $ELONXCAT 才能进行质押。您的钱包中需要有 $BNB 来处理质押和质押奖励索赔。',
    tier_txt_35:
      '如果您质押了 $ELONXCAT，则必须等待至少 14 天的归属期才能领取。仅可使用在以太坊上购买的 $BNB 进行质押。您的钱包中需要有 $BNB 来处理质押和质押奖励索赔。',
    tier_txt_36:
      '质押是将代币发送到智能合约以将其锁定一段时间的行为。这使您可以随着时间的推移以动态比率赚取更多 $ELONXCAT 代币。 ELONXCAT 质押即将在 BNB 网络上推出。欲了解更多信息，请点击导航栏中的“质押”。',
    tier_txt_37:
      '要质押 $ELONXCAT，请访问此处：https://ELONXCAT/en/stake 只有在 BNB 上购买 $ELONXCAT 才能进行质押。您的钱包中需要有 $BNB 来处理质押和质押奖励索赔。',
    tier_txt_38:
      '如果您质押了 $ELONXCAT，则必须等待至少 14 天的归属期才能领取。仅可使用在以太坊上购买的 $BNB 进行质押。您的钱包中需要有 $BNB 来处理质押和质押奖励索赔。',
    tier_txt_39:
      'ELONXCAT 巴拿马办事处：43 楼，大洋洲商务Plaza, Tower 2000, Punta Colon Street, Urbanisación Punta Pacifica, 巴拿马城, 巴拿马',
    tier_txt_40:
      '免责声明：ELONXCAT 是一种模因币，其价值完全基于 ELONXCAT 模因的普遍文化资本。 加密货币的价值可以上涨或下跌。谨慎投资。',
  },

  TOKENBURNINGPLAN: '代币销毁计划',
  OFFICIALELONXCATVIDEO: 'ELONXCAT 官方视频',
  steps: {
    step_title_1: '将加密货币存入您的钱包',
    step_title_2: '选择网络',
    step_title_3: '连接钱包',
    step_title_4: '购买代币',
    step_title_5: '检查您的钱包中的 EXC$ 代币。',
    step_title_6: '享受与 ELONXCAT 的加密旅程！',
    step_sub_title_1:
      '请将 BNB 或 USDT 存入您的加密货币钱包（例如 MetaMask）。',
    step_sub_title_2: '请确保将钱包的区块链网络切换到 BSC。',
    step_sub_title_3: '在预售小工具中找到“连接钱包”按钮，并连接您首选的钱包。',
    step_sub_title_4:
      '输入您希望兑换为 $EXC 的 BNB 或 USDT 金额，并在钱包中确认交易。',
    step_sub_title_5:
      '如果您在钱包中看不到 EXC，请添加代币。代币合约地址是 0xA4b3445A58111ABD407C34402aB59B0FE05bFF5a。',
    step_sub_title_6:
      'ELONXCAT 团队将把 ELONXCAT 打造成最好的 Meme 币项目。让我们与团队一起享受加密旅程吧！',
    how_to_buy_txt_1: "'如何购买 $EXC' 视频已准备好。",
    how_to_buy_txt_2: '点击按钮以观看多种语言的“如何购买”视频！',
  },
  tokens_burning_plan_description1: '在交易所上市后，',
  tokens_burning_plan_description2: '我们的团队将逐步销毁超过 90% 的代币。',
  tokens_burning_plan_description3: '代币的总供应量将持续减少，价格将会上涨。',
  tokens_burning_plan_description4: '代币的总供应量将持续减少，价格将会上涨。',
};
