export default {
  ABOUT: 'wstęp',
  TOKENOMICS: 'Tokenomika',
  STAKING: 'obstawianie',
  'HOW TO BUY': 'Jak kupić',
  ROADMAP: 'plan działania',
  FAQ: 'Często zadawane pytania',
  'BUY NOW': 'kup teraz',
  'ELONXCAT is the real': 'ELONXCAT jest prawdziwy',
  ELONMUSK: 'Elona Muska',
  'SpaceX and X': 'SpaceX i',
  'meme project': 'projekt mema',
  'Advanced Web3 Ecosystem': 'Zaawansowany ekosystem Web3',
  'Potential of project': 'Potencjał projektu',
  'Market cap Target': 'docelowa kapitalizacja rynkowa',
  Investors: 'inwestorzy',
  'WHITE PAPER': 'biała księga',
  AUDIT: 'Dzięki',
  'Presale will start soon. Join the Presale':
    'Wkrótce rozpocznie się przedsprzedaż. Weź udział w przedsprzedaży',
  'TOKEN DISTRIBUTION DATE': 'Data dystrybucji tokenu',
  Raised: 'Zebrana kwota',
  'ELONXCAT sold': 'Wielkość sprzedaży ELONXCAT',
  BUY: 'zakup',
  'MULTI BRIDGE UTILITY': 'Narzędzie wielu mostów',
  'Total Supply': 'całkowita podaż',
  'ELONXCAT ACHIEVEMENTS': 'Wydajność ELONXCAT',
  'Ecosystem Percentage': 'stosunek ekosystemu',
  'ELONXCAT GET TOKEN GAME': 'GRA ZDOBĄDZ TOKEN ELONXCAT',
  'ELONXCAT GAME PORTAL SOON': 'Portal gier ELONXCAT już wkrótce',
  'NFT MARKET PLACE': 'Rynek NFT',
  'Unique collections': 'wyjątkowa kolekcja',
  'Launch at the same time as the project': 'Wydany jednocześnie z projektem',
  'NFT Box and Airdrop': 'Pudełko NFT i zrzut',
  'DISCOVER NFT': 'Przeglądaj NFT',
  'AI-Powerde Content': 'Treści oparte na sztucznej inteligencji',
  'AI Image Generator': 'Generator obrazów AI',
  'AI Chat Bot': 'Bot czatowy AI',
  'AI Code': 'Kod AI',
  'COIN SWAP': 'Zamiana monet',
  'ELONXCAT ALLOCAT STASTISTICS': 'Statystyki alokacji ELONXCAT',
  'ELONXCAT STAKING SERVICE IS COMING SOON':
    'Usługa stakingu ELONXCAT już wkrótce',
  'WELCOME TO ELONXCAT STAKING': 'Witamy w stakingu ELONXCAT',
  'WITHDRAW STAKED TOKENS': 'Wycofaj postawione tokeny',
  'STAKED BALANCE': 'Bilans stawek',
  'YOUR STAKEABLE': 'Saldo dostępne do obstawiania',
  'TOTAL STAKED': 'całkowita stawka',
  'ESTIMATED REWARDS': 'oczekiwana nagroda',
  'Rewards rate is dynamic': 'Stosunek nagrody jest dynamiczny',
  Monthly: 'miesięczny',
  Daily: 'zerknąć',
  'CURRENT REWARDS': 'aktualna nagroda',
  'TOTAL REWARDS': 'całkowita nagroda',
  'CLAIM REWARDS': 'roszczenie o odszkodowanie',
  'TOTAL SUPPLY': 'całkowita podaż',
  'OUR PARTNERS and BACKERS': 'Nasi Partnerzy i Sponsorzy',
  'ELONXCAT TEAM': 'Zespół ELONXCAT',
  Founder: 'założyciel',
  'Creative Director': 'dyrektor kreatywny',
  'Community manger': 'menadżer społeczności',
  'CONNECT YOUR WALLET': 'Połączenie z portfelem',
  'ENTER PRESALE': 'Udział w przedsprzedaży',
  'CLAIM ELONXCAT': 'Rozliczenia ELONXCAT',
  'ROAD MAP': 'plan działania',
  DEVELOPMENT: 'rozwój',
  'MEME DOMINATION': 'Darowizna memowa',
  'FREQUENTLY ASKED QUESTIONS': 'Często zadawane pytania',
  'What is a presale': 'Co to jest przedsprzedaż?',
  'What is ELONXCAT': 'Co to jest ELONXCAT?',
  'How do I participate in the presale': 'Jak wziąć udział w przedsprzedaży?',
  'When do I receive my presale tokens':
    'Kiedy otrzymam tokeny przedsprzedażowe?',
  'What is ELONXCAT staking': 'Co to jest stakowanie ELONXCAT?',
  'How do I stake my tokens': 'Jak postawić swoje tokeny?',
  'I staked my tokens during presale':
    'Postawiłem tokeny podczas przedsprzedaży, kiedy mogę je odebrać?',
  'STAY CONNECTED': 'pozostań w kontakcie',
  'PRIVACY POLICY': 'Polityka prywatności',
  'TERMS and CONDITIONS': 'Warunki użytkowania',
  'PRESALE ALLOCATION': 'Przydział przedsprzedażowy',
  AIRDROP: 'zrzut',
  PROJECTFUNDS: 'finansowanie projektu',
  LIQUIDITY: 'płynność',
  MARKETING: 'marketingu',
  EXCHANGES: 'giełda',
  'ECOSYSTEM FUNDS': 'fundusze ekosystemowe',
  EXPANSION: 'ekspansja',
  'HELP CENTER': 'Centrum pomocy',
  "If it doesn't work well on mobile":
    'Jeśli nie działa dobrze na urządzeniu mobilnym',
  'please try using PC': 'spróbuj użyć komputera',
  'twitter-announce1':
    'Możesz sprawdzić ogłoszenia w czasie rzeczywistym na X (Twitter)',
  'twitter-announce2':
    'Nie możesz zobaczyć całego posta, chyba że obserwujesz.',
  'twitter-announce3': 'Upewnij się, że obserwujesz!',

  texts: {
    txt_1:
      'Nasza własna technologia o nazwie X obsługuje ten multichain. X obsługuje funkcję migracji łańcucha, umożliwiając posiadaczom ELONXCAT swobodne poruszanie się pomiędzy wiodącymi łańcuchami kryptowalut.',
    txt_2:
      'ELONXCAT to token memowy zbudowany w sieci wielołańcuchowej obejmującej łańcuch BNB, Ethereum, Polygon, Solana i Tron.',
    txt_3:
      'ELONXCAT stara się zjednoczyć społeczność memów kryptowalutowych i stać się pionierem w zakresie narzędzi wielołańcuchowych.',
    txt_4:
      'Naszym celem jest, aby nasza nazwa lśniła na każdym DEX, każdym CEX i wszędzie indziej.',
    txt_5: 'Wykorzystując',
    txt_6: 'X będzie w przyszłości nadal rozwijać te multichains.',
  },
  'PRESALE TIERS': 'etap przedsprzedażowy',

  tiers: {
    tier_txt_1:
      'Początkowa cena przedsprzedaży wynosi 0,0000009 USD za miejsce, która zmieni się na Tier2 po 20 dniach.',
    tier_txt_2:
      'Cena tego poziomu jest ustalona na poziomie 0,000001 USD od przedsprzedaży, która zmieni się na Tier3 po 20 dniach.',
    tier_txt_3:
      'Cena tego poziomu jest ustalona na poziomie 0,000005 USD od przedsprzedaży, która zmieni się na cenę końcową po 20 dniach.',
    tier_txt_4:
      'Cena przedsprzedaży tego poziomu wynosi 0,000009 USD, która zakończy się wraz z końcem przedsprzedaży ELONXCAT po tej cenie.',
    tier_txt_5:
      'ELONXCAT ma na celu być najlepszym DEXem na BSC z funkcjami takimi jak swapy tokenów, pule płynności i rolnictwo plonów.',
    tier_txt_6:
      'ELONXCAT Quest łączy w sobie gry i kryptowalutę, dając Ci możliwość zdobywania tokenów Lope poprzez zadania i łamigłówki w świecie blockchain.',
    tier_txt_7:
      'Projekt ELONXCAT NFT łączy sztukę cyfrową z memecoinami ELONXCAT, aby zapewnić unikalne, bogate w użyteczność przedmioty kolekcjonerskie zwiększające zaangażowanie społeczności.',
    tier_txt_8:
      'ELONXCAT łączy finanse i kreatywność w przyjaznej dla użytkownika platformie, integrując sztuczną inteligencję do rozmów, udzielania porad handlowych, tworzenia memów i nie tylko.',
    tier_txt_9:
      'ELONXCAT GET TOKEN GAME to gra blockchainowa. Ekosystem ELONXCAT łączy gry z tokenomiką, pozwalając graczom wykonywać różne zadania i uczestniczyć w aktywnościach społecznych, aby zdobywać tokeny. Celem tej gry jest nie tylko prezentacja naszej technologii blockchain, ale także pomoc w lepszym poznaniu naszego projektu i zarabianiu pieniędzy poprzez aktywny udział w grze.',
    tier_txt_10:
      'ELONXCAT Coin NFT Marketplace posiada unikalną kolekcję ze specjalnymi i unikalnymi funkcjami.',
    tier_txt_11:
      'ELONXCAT Coin NFT będą notowane wraz z projektem na wszystkich rynkach światowych, a także na rynku własnym projektu.',
    tier_txt_12:
      'Na rynku dostępny jest duży program zrzutów, który zostanie aktywowany podczas przedsprzedaży skrzynek NFT i tokenów.',
    tier_txt_13:
      'Twórz zoptymalizowane pod kątem SEO blogi, e-maile sprzedażowe i nie tylko... Oszczędzaj czas i uzyskuj świetne wyniki. Wreszcie istnieje naprawdę użyteczne narzędzie do tworzenia treści.',
    tier_txt_14:
      'Wizualizuj o czym marzysz. Twórz obrazy z tekstem. Błyskawicznie twórz wspaniałe obrazy dla swoich artystycznych innowacji dzięki naszemu generatorowi obrazów AI.',
    tier_txt_15:
      'Po prostu wybierz swojego asystenta i porozmawiaj z przyjaznym chatbotem, aby generować pomysły, ulepszać swoje treści, a czasem nawet zapewniać śmiech.',
    tier_txt_16:
      'Gotowy do błyskawicznego pisania kodu? Z łatwością twórz kod za pomocą generatora kodu AI: zapewnia natychmiastowe rozwiązania Twoich potrzeb programistycznych.',
    tier_txt_17:
      'ElonXCat Swap dąży do tego, aby być wiodącą zdecentralizowaną platformą wymiany (DEX) na Binance Smart Chain (BSC).',
    tier_txt_18:
      'Dostosowany do monety meme ElonXCat i jej ekosystemu, ElonXCat Swap ułatwia płynne wymiany tokenów, oferuje pule płynności i wprowadza innowacyjne funkcje, takie jak yield farming i staking. Te funkcjonalności mają na celu zwiększenie płynności, zapewnienie uczciwego handlu i przyczynienie się do ogólnego wzrostu społeczności ElonXCat.',
    tier_txt_19:
      'Dystrybucja nagród w postaci tokenów ELONXCAT będzie dokonywana przez dwa lata po stawce 708,72 $ELONXCAT za blok Ethereum i będzie można je odebrać po aktywacji roszczenia.',
    tier_txt_20:
      'Połącz swój portfel w ciągu kilku sekund za pomocą Metamask lub Trust Wallet.',
    tier_txt_21:
      'Zdecyduj, ile $ELONXCAT chcesz kupić.   Możesz kupować za USDT, BNB, ETH i USDT.',
    tier_txt_22:
      'Po zakończeniu przedsprzedaży możesz odebrać tokeny $ELONXCAT za pośrednictwem tego samego portfela i łańcucha, którego użyłeś do zakupu.',
    tier_txt_23: 'Narodziny ELONMUSKA &',
    tier_txt_24:
      'Budowanie społeczności  Generowanie tokenów  Kanały społecznościowe  Narodziny',
    tier_txt_25:
      'KYC i audyt  Pompa marketingowa  Rozpoczyna się przedsprzedaż',
    tier_txt_26:
      'Rozpocznij marketing  CoinMarketCap   Złóż wniosek  Złóż wniosek o CoinGecko  Aktualizacja ElonXCAT NFT  Tworzenie gier ElonXCAT  Aktualizacja stakingu',
    tier_txt_27:
      'Pierwsze ogłoszenie DEX   Aktualizacja DEXTools   Aktualizacja AI ElonXCAT  Automatyczne nagrywanie',
    tier_txt_28:
      'Ciągły marketing  Zachęty społecznościowe  Partnerstwa z innymi platformami  Docelowa liczba posiadaczy: 1 milion+',
    tier_txt_29:
      'Przedsprzedaż stanowi dla inwestorów wyjątkową okazję do zakupu nowych tokenów lub kryptowalut przed ich oficjalnym wprowadzeniem na rynek. Ta faza wczesnego dostępu pozwala nam wykorzystać potencjał projektu od samego początku.',
    tier_txt_30:
      'ELONXCAT to naprawdę zaawansowana, wielołańcuchowa moneta memowa, która w rzeczywistości jest ELONMUSK & Zaawansowany ekosystem Web3: Staking | DEX | sztuczna inteligencja | gry | Rynek NFT.',
    tier_txt_31:
      'Możesz wziąć udział w przedsprzedaży w sieci BNB. Połącz Best Wallet lub inny portfel z powyższym widżetem przedsprzedażowym i zarezerwuj tokeny $ELONXCAT za pomocą BNB, ETH lub USDT.',
    tier_txt_32:
      'Po zakończeniu zakupu w przedsprzedaży możesz użyć tego samego portfela, aby odebrać tokeny przed pierwszą aukcją DEX. Daty rozliczeń i ofert zostaną ogłoszone na naszych kontach w mediach społecznościowych.',
    tier_txt_33:
      'Stakowanie to czynność polegająca na wysyłaniu tokenów do inteligentnej umowy w celu zablokowania ich na określony czas. Dzięki temu możesz z czasem zarobić więcej tokenów $ELONXCAT w dynamicznym tempie. Stakowanie ELONXCAT będzie wkrótce dostępne w sieci BNB. Aby uzyskać więcej informacji, kliknij „Stakowanie” na pasku nawigacyjnym.',
    tier_txt_34:
      'Aby postawić $ELONXCAT, odwiedź tutaj: https://ELONXCAT/en/staking Stakowanie jest możliwe tylko za $ELONXCAT zakupiony na BNB. Będziesz potrzebować $BNB w swoim portfelu, aby przetworzyć roszczenia o nagrodę za odstawienie i obstawienie.',
    tier_txt_35:
      'Jeśli postawiłeś $ELONXCAT, musisz odczekać okres nabywania uprawnień wynoszący co najmniej 14 dni, zanim będziesz mógł go odebrać. Stakowanie jest możliwe tylko za $BNB zakupione na Ethereum. Będziesz potrzebować $BNB w swoim portfelu, aby przetworzyć roszczenia o nagrodę za odstawienie i obstawienie.',
    tier_txt_36:
      'Stakowanie to czynność polegająca na wysyłaniu tokenów do inteligentnej umowy w celu zablokowania ich na określony czas. Dzięki temu możesz z czasem zarobić więcej tokenów $ELONXCAT w dynamicznym tempie. Stakowanie ELONXCAT będzie wkrótce dostępne w sieci BNB. Aby uzyskać więcej informacji, kliknij „Stakowanie” na pasku nawigacyjnym.',
    tier_txt_37:
      'Aby postawić $ELONXCAT, odwiedź tutaj: https://ELONXCAT/en/staking Stakowanie jest możliwe tylko za $ELONXCAT zakupiony na BNB. Będziesz potrzebować $BNB w swoim portfelu, aby przetworzyć roszczenia o nagrodę za odstawienie i obstawienie.',
    tier_txt_38:
      'Jeśli postawiłeś $ELONXCAT, musisz odczekać okres nabywania uprawnień wynoszący co najmniej 14 dni, zanim będziesz mógł go odebrać. Stakowanie jest możliwe tylko za $BNB zakupione na Ethereum. Będziesz potrzebować $BNB w swoim portfelu, aby przetworzyć roszczenia o nagrodę za odstawienie i obstawienie.',
    tier_txt_39:
      'BIURO ELONXCAT w PANAMIE: 43. piętro, Oceania Business Plaza, Tower 2000, Punta Colon Street, Urbanisación Punta  Pacifica, Panama City, Panama',
    tier_txt_40:
      'Zastrzeżenie: ELONXCAT to moneta memowa, jej wartość opiera się wyłącznie na uniwersalnym kapitale kulturowym memów ELONXCAT.  Wartość kryptowaluty może rosnąć lub spadać. Inwestuj ostrożnie.',
  },

  TOKENBURNINGPLAN: 'PLAN SPALANIA TOKENÓW',
  OFFICIALELONXCATVIDEO: 'OFICJALNE WIDEO ELONXCAT',
  steps: {
    step_title_1: 'Zdeponuj kryptowaluty do swojego portfela',
    step_title_2: 'Wybierz sieć',
    step_title_3: 'Połącz portfel',
    step_title_4: 'Kup tokeny',
    step_title_5: 'Sprawdź token EXC$ w swoim portfelu',
    step_title_6: 'Ciesz się swoją podróżą kryptowalutową z ELONXCAT!',
    step_sub_title_1:
      'Proszę zdeponować BNB lub USDT do swojego portfela kryptowalutowego (np. MetaMask).',
    step_sub_title_2:
      'Upewnij się, że przełączysz sieć blockchain portfela na BSC.',
    step_sub_title_3:
      "Znajdź przycisk 'połącz portfel' na widżecie przedsprzedaży i połącz swój preferowany portfel.",
    step_sub_title_4:
      'Wprowadź kwotę BNB lub USDT, którą chcesz wymienić na $EXC i potwierdź transakcję w swoim portfelu.',
    step_sub_title_5:
      'Jeśli nie widzisz EXC w swoim portfelu, dodaj token. Adres kontraktu tokena to 0xA4b3445A58111ABD407C34402aB59B0FE05bFF5a',
    step_sub_title_6:
      'Zespół ELONXCAT będzie rozwijać ELONXCAT w najlepszy projekt meme coin. Cieszmy się podróżą kryptowalutową z naszym zespołem!',
    how_to_buy_txt_1: "Film 'JAK KUPIĆ $EXC' jest gotowy.",
    how_to_buy_txt_2:
      "Kliknij przycisk, aby obejrzeć 'JAK KUPIĆ' w różnych językach!",
  },
  tokens_burning_plan_description1: 'Po notowaniu na giełdzie,',
  tokens_burning_plan_description2:
    'Nasz zespół stopniowo spali ponad 90% tokenów.',
  tokens_burning_plan_description3:
    'Całkowita podaż tokenów będzie nadal malała, a cena wzrośnie.',
  tokens_burning_plan_description4:
    'Całkowita podaż tokenów będzie nadal malała, a cena wzrośnie.',
};
