export default {
  ABOUT: '介紹',
  TOKENOMICS: '代幣經濟學',
  STAKING: '質押',
  'HOW TO BUY': '如何購買',
  ROADMAP: '路線圖',
  FAQ: '常見問題解答',
  'BUY NOW': '立即購買',
  'ELONXCAT is the real': 'ELONXCAT 是真實的',
  ELONMUSK: '馬斯克',
  'SpaceX and X': 'SpaceX 和',
  'meme project': '模因項目',
  'Advanced Web3 Ecosystem': '先進的Web3生態系統',
  'Potential of project': '專案潛力',
  'Market cap Target': '目標市值',
  Investors: '投資者',
  'WHITE PAPER': '白書',
  AUDIT: '謝謝',
  'Presale will start soon. Join the Presale': '預售即將開始。參與預售',
  'TOKEN DISTRIBUTION DATE': '代幣分發日期',
  Raised: '籌集金額',
  'ELONXCAT sold': 'ELONXCAT銷量',
  BUY: '購買',
  'MULTI BRIDGE UTILITY': '多橋實用程式',
  'Total Supply': '總供應量',
  'ELONXCAT ACHIEVEMENTS': 'ELONXCAT 效能',
  'Ecosystem Percentage': '生態係比例',
  'ELONXCAT GET TOKEN GAME': 'ELONXCAT 獲取代幣遊戲',
  'ELONXCAT GAME PORTAL SOON': 'ELONXCAT 遊戲入口網站即將推出',
  'NFT MARKET PLACE': 'NFT 市場',
  'Unique collections': '獨特的收藏',
  'Launch at the same time as the project': '與專案同時發布',
  'NFT Box and Airdrop': 'NFT盒子和空投',
  'DISCOVER NFT': '探索 NFT',
  'AI-Powerde Content': '基於人工智慧的內容',
  'AI Image Generator': '人工智慧圖像生成器',
  'AI Chat Bot': '人工智慧聊天機器人',
  'AI Code': '人工智慧程式碼',
  'COIN SWAP': '硬幣交換',
  'ELONXCAT ALLOCAT STASTISTICS': 'ELONXCAT分配統計',
  'ELONXCAT STAKING SERVICE IS COMING SOON': 'ELONXCAT 質押服務即將推出',
  'WELCOME TO ELONXCAT STAKING': '歡迎來到 ELONXCAT 質押',
  'WITHDRAW STAKED TOKENS': '提取質押代幣',
  'STAKED BALANCE': '質押餘額',
  'YOUR STAKEABLE': '可用於質押的餘額',
  'TOTAL STAKED': '總質押',
  'ESTIMATED REWARDS': '預期回報',
  'Rewards rate is dynamic': '獎勵比例是動態的',
  Monthly: '每月',
  Daily: '一眼',
  'CURRENT REWARDS': '目前獎勵',
  'TOTAL REWARDS': '總獎勵',
  'CLAIM REWARDS': '賠償要求',
  'TOTAL SUPPLY': '總供應量',
  'OUR PARTNERS and BACKERS': '我們的合作夥伴和贊助商',
  'ELONXCAT TEAM': 'ELONXCAT團隊',
  Founder: '創辦人',
  'Creative Director': '創意總監',
  'Community manger': '社群經理',
  'CONNECT YOUR WALLET': '錢包連接',
  'ENTER PRESALE': '預售參與',
  'CLAIM ELONXCAT': 'ELONXCAT 計費',
  'ROAD MAP': '路線圖',
  DEVELOPMENT: '發展',
  'MEME DOMINATION': '模因捐贈',
  'FREQUENTLY ASKED QUESTIONS': '常見問題解答',
  'What is a presale': '什麼是預售？',
  'What is ELONXCAT': '什麼是 ELONXCAT？',
  'How do I participate in the presale': '我如何參與預售？',
  'When do I receive my presale tokens': '我什麼時候可以收到預售代幣？',
  'What is ELONXCAT staking': '什麼是 ELONXCAT 質押？',
  'How do I stake my tokens': '我該如何質押我的代幣？',
  'I staked my tokens during presale':
    '我在預售期間質押了代幣，我什麼時候可以領取它們？',
  'STAY CONNECTED': '保持聯繫',
  'PRIVACY POLICY': '隱私權政策',
  'TERMS and CONDITIONS': '使用條款',
  'PRESALE ALLOCATION': '預售分配',
  AIRDROP: '空投',
  PROJECTFUNDS: '專案資助',
  LIQUIDITY: '流動性',
  MARKETING: '行銷',
  EXCHANGES: '交換',
  'ECOSYSTEM FUNDS': '生態系基金',
  EXPANSION: '擴張',
  'HELP CENTER': '幫助中心',
  "If it doesn't work well on mobile": '如果在手機上無法正常運行',
  'please try using PC': '請嘗試使用電腦',
  'twitter-announce1': '您可以在X（Twitter）上查看即時公告',
  'twitter-announce2': '除非您關注，否則無法查看完整貼文。',
  'twitter-announce3': '請務必關注！',

  texts: {
    txt_1:
      '我們自己的技術 X 支援這種多鏈。 X支援鏈遷移功能，讓ELONXCAT持有者在領先的加密貨幣鏈之間自由移動。',
    txt_2:
      'ELONXCAT 是建立在 BNB 鏈、以太坊、Polygon、Solana 和 Tron 的多鏈網路上的 meme 代幣。',
    txt_3: 'ELONXCAT 致力於團結加密貨幣迷因社區，成為多鏈實用程式的先驅。',
    txt_4:
      '我們的目標是讓我們的名字閃耀在每個 DEX、每個 CEX 以及其他任何地方。',
    txt_5: '利用',
    txt_6: 'X未來將繼續擴展這些多鏈。',
  },
  'PRESALE TIERS': '預售階段',

  tiers: {
    tier_txt_1: '初始預售價格為每座位 0.0000009 美元，20 天後將變為 Tier2。',
    tier_txt_2:
      '此層級的價格從預售開始設定為 0.000001 美元，20 天後將變為 Tier3。',
    tier_txt_3:
      '此層級的價格從預售開始設定為 0.000005 美元，20 天後將變為最終價格。',
    tier_txt_4:
      '此層級的預售價格為 0.000009 美元，將以這個價格結束 ELONXCAT 預售。',
    tier_txt_5:
      'ELONXCAT 旨在成為 BSC 上最好的 DEX，具有代幣互換、流動性池和流動性挖礦等功能。',
    tier_txt_6:
      'ELONXCAT Quest 將遊戲和加密貨幣結合在一起，讓您有機會透過區塊鏈世界中的任務和謎題賺取 Lope 代幣。',
    tier_txt_7:
      'ELONXCAT NFT 計畫將數位藝術與 ELONXCAT memecoin 結合，提供獨特、實用性豐富的收藏品，以增強社區參與度。',
    tier_txt_8:
      'ELONXCAT 透過整合用於聊天、交易技巧、表情包創建等的人工智慧，將金融和創造力結合在一個用戶友好的平台中。',
    tier_txt_9:
      'ELONXCAT GET TOKEN GAME 是一款區塊鏈遊戲。ELONXCAT 生態系統將遊戲與代幣經濟結合，讓玩家完成各種任務並參與社交活動以賺取代幣。這款遊戲的目的不僅是展示我們的區塊鏈技術，還幫助您透過積極參與遊戲賺取收益，進一步熟悉我們的項目。',
    tier_txt_10:
      'ELONXCAT Coin NFT 市場擁有獨特的收藏品，具有特殊和獨特的功能。',
    tier_txt_11:
      'ELONXCAT Coin NFT 將與該項目一起在所有全球市場以及該項目自己的市場上上市。',
    tier_txt_12:
      '該市場包括一個大型空投計劃，該計劃將在 NFT 盒子和代幣預售期間啟動。',
    tier_txt_13:
      '創建 SEO 優化的部落格、銷售電子郵件等...節省時間並獲得出色的結果。最後，有一個真正可用的創作工具。',
    tier_txt_14:
      '想像你的夢想。建立帶有文字的圖像。使用我們的 AI 圖像生成器立即為您的藝術創新創建令人驚嘆的圖像。',
    tier_txt_15:
      '只需選擇您的助手並與友好的聊天機器人聊天即可產生想法、改進您的內容，有時甚至帶來笑聲。',
    tier_txt_16:
      '準備好以閃電般的速度編寫程式碼了嗎？使用 AI 代碼產生器輕鬆編碼：為您的程式需求提供即時解決方案。',
    tier_txt_17:
      'ElonXCat Swap 旨在成為幣安智能鏈（BSC）上的領先去中心化交易所（DEX）平台。',
    tier_txt_18:
      '針對 ElonXCat 迷因幣及其生態系統量身打造，ElonXCat Swap 促進無縫的代幣交換，提供流動性池，並引入創新功能，如收益耕作和質押。這些功能旨在增強流動性，確保公平交易，並促進 ElonXCat 社群的整體成長。',
    tier_txt_19:
      'ELONXCAT 代幣獎勵將在兩年內以每個以太坊區塊 708.72 $ELONXCAT 的價格進行分配，一旦啟動即可領取。',
    tier_txt_20: '使用 Metamask 或 Trust Wallet 在幾秒鐘內連接您的錢包。',
    tier_txt_21:
      '決定您要購買多少 $ELONXCAT。  您可以使用 USDT 與 BNB、ETH、USDT 一起購買。',
    tier_txt_22:
      '預售結束後，您可以透過您購買時使用的錢包和鏈來領取您的 $ELONXCAT 代幣。',
    tier_txt_23: 'ELONMUSK 的出生 &',
    tier_txt_24: '社群建構 代幣生成 社群管道 誕生',
    tier_txt_25: 'KYC & 審計 行銷幫浦 預售開始',
    tier_txt_26:
      '開始行銷 CoinMarketCap 申請 申請CoinGecko ElonXCAT NFT更新 ElonXCAT遊戲開發 質押更新',
    tier_txt_27: '首個DEX上線 DEXTools更新 ElonXCAT AI更新 自動燒錄',
    tier_txt_28: '持續行銷 社群激勵 與其他平台合作 目標持有量：100萬+',
    tier_txt_29:
      '預售為投資者提供了在正式推出之前購買新代幣或加密貨幣的獨特機會。這個早期訪問階段使我們能夠從一開始就充分利用該專案的潛力。',
    tier_txt_30:
      'ELONXCAT 是一種真正先進的多鏈迷因幣，實際上是 ELONMUSK &先進的 Web3 生態系：質押 |去中心化交易所 |人工智慧 |遊戲| NFT 市場。',
    tier_txt_31:
      '您可以參與BNB鏈上的預售。將 Best Wallet 或其他錢包連接到上面的預售小部件，並使用 BNB、ETH 或 USDT 儲備 $ELONXCAT 代幣。',
    tier_txt_32:
      '預售購買完成後，您可以在首次 DEX 上市之前使用同一個錢包領取您的代幣。帳單和上市日期將在我們的社交媒體帳戶上公佈。',
    tier_txt_33:
      '質押是將代幣發送到智能合約以將其鎖定一段時間的行為。這使您可以隨著時間的推移以動態比率賺取更多 $ELONXCAT 代幣。 ELONXCAT 質押即將在 BNB 網路上推出。欲了解更多信息，請點擊導航欄中的“質押”。',
    tier_txt_34:
      '要質押 $ELONXCAT，請造訪此處：https://ELONXCAT/en/stake 只有在 BNB 上購買 $ELONXCAT 才能進行質押。您的錢包中需要有 $BNB 來處理質押和質押獎勵索賠。',
    tier_txt_35:
      '如果您質押了 $ELONXCAT，則必須等待至少 14 天的歸屬期才能領取。僅可使用在以太坊上購買的 $BNB 進行質押。您的錢包中需要有 $BNB 來處理質押和質押獎勵索賠。',
    tier_txt_36:
      '質押是將代幣發送到智能合約以將其鎖定一段時間的行為。這使您可以隨著時間的推移以動態比率賺取更多 $ELONXCAT 代幣。 ELONXCAT 質押即將在 BNB 網路上推出。欲了解更多信息，請點擊導航欄中的“質押”。',
    tier_txt_37:
      '要質押 $ELONXCAT，請造訪此處：https://ELONXCAT/en/stake 只有在 BNB 上購買 $ELONXCAT 才能進行質押。您的錢包中需要有 $BNB 來處理質押和質押獎勵索賠。',
    tier_txt_38:
      '如果您質押了 $ELONXCAT，則必須等待至少 14 天的歸屬期才能領取。僅可使用在以太坊上購買的 $BNB 進行質押。您的錢包中需要有 $BNB 來處理質押和質押獎勵索賠。',
    tier_txt_39:
      'ELONXCAT 巴拿馬辦事處：43 樓，大洋洲商務Plaza, Tower 2000, Punta Colon Street, Urbanisación Punta Pacifica, 巴拿馬城, 巴拿馬',
    tier_txt_40:
      '免責聲明：ELONXCAT 是一種迷因幣，其價值完全基於 ELONXCAT 迷因的普遍文化資本。謹慎投資。',
  },
  TOKENBURNINGPLAN: '代幣銷毀計劃',
  OFFICIALELONXCATVIDEO: 'ELONXCAT 官方視頻',
  steps: {
    step_title_1: '將加密貨幣存入您的錢包',
    step_title_2: '選擇網絡',
    step_title_3: '連接錢包',
    step_title_4: '購買代幣',
    step_title_5: '檢查您錢包中的 EXC$ 代幣。',
    step_title_6: '與 ELONXCAT 一起享受加密之旅！',
    step_sub_title_1:
      '請將 BNB 或 USDT 存入您的加密貨幣錢包（例如 MetaMask）。',
    step_sub_title_2: '請確保將錢包的區塊鏈網絡切換至 BSC。',
    step_sub_title_3: '在預售小工具中找到“連接錢包”按鈕，並連接您偏好的錢包。',
    step_sub_title_4:
      '輸入您希望兌換為 $EXC 的 BNB 或 USDT 金額，並在錢包中確認交易。',
    step_sub_title_5:
      '如果您在錢包中看不到 EXC，請添加代幣。代幣合約地址是 0xA4b3445A58111ABD407C34402aB59B0FE05bFF5a。',
    step_sub_title_6:
      'ELONXCAT 團隊將把 ELONXCAT 打造成最好的 Meme 幣項目。讓我們與團隊一起享受加密之旅吧！',
    how_to_buy_txt_1: '“如何購買 $EXC”視頻已準備好。',
    how_to_buy_txt_2: '點擊按鈕觀看多種語言的“如何購買”視頻！',
  },
  tokens_burning_plan_description1: '在交易所上市後，',
  tokens_burning_plan_description2: '我們的團隊將逐步銷毀超過 90% 的代幣。',
  tokens_burning_plan_description3: '代幣的總供應量將持續減少，價格將上升。',
  tokens_burning_plan_description4: '代幣的總供應量將持續減少，價格將上升。',
};
