export default {
  ABOUT: 'introducere',
  TOKENOMICS: 'Tokenomics',
  STAKING: 'miza',
  'HOW TO BUY': 'Cum se cumpără',
  ROADMAP: 'foaie de parcurs',
  FAQ: 'Întrebări frecvente',
  'BUY NOW': 'cumpara acum',
  'ELONXCAT is the real': 'ELONXCAT este real',
  ELONMUSK: 'Elon Musk',
  'SpaceX and X': 'SpaceX și',
  'meme project': 'proiect meme',
  'Advanced Web3 Ecosystem': 'Ecosistem Web3 avansat',
  'Potential of project': 'Potentialul proiectului',
  'Market cap Target': 'capitalizarea de piata tinta',
  Investors: 'investitori',
  'WHITE PAPER': 'carte albă',
  AUDIT: 'Mulţumesc',
  'Presale will start soon. Join the Presale':
    'Pre-vânzarea începe în curând. Participați la pre-vânzare',
  'TOKEN DISTRIBUTION DATE': 'Data distribuirii jetonului',
  Raised: 'Suma strânsă',
  'ELONXCAT sold': 'Volumul vânzărilor ELONXCAT',
  BUY: 'cumpărare',
  'MULTI BRIDGE UTILITY': 'Utilitate Multiple Bridge',
  'Total Supply': 'oferta totala',
  'ELONXCAT ACHIEVEMENTS': 'Performanță ELONXCAT',
  'Ecosystem Percentage': 'raportul ecosistemului',
  'ELONXCAT GET TOKEN GAME': 'JOC DE OBȚINERE TOKEN ELONXCAT',
  'ELONXCAT GAME PORTAL SOON': 'Portalul de jocuri ELONXCAT va veni în curând',
  'NFT MARKET PLACE': 'Piața NFT',
  'Unique collections': 'colecție unică',
  'Launch at the same time as the project': 'Lansat simultan cu proiectul',
  'NFT Box and Airdrop': 'Caseta NFT și airdrop',
  'DISCOVER NFT': 'Explorați NFT-urile',
  'AI-Powerde Content': 'Conținut bazat pe inteligență artificială',
  'AI Image Generator': 'Generator de imagini AI',
  'AI Chat Bot': 'bot de chat AI',
  'AI Code': 'Cod AI',
  'COIN SWAP': 'Schimb de monede',
  'ELONXCAT ALLOCAT STASTISTICS': 'Statistici de alocare ELONXCAT',
  'ELONXCAT STAKING SERVICE IS COMING SOON':
    'Serviciul de staking ELONXCAT va fi în curând',
  'WELCOME TO ELONXCAT STAKING': 'Bun venit la ELONXCAT Staking',
  'WITHDRAW STAKED TOKENS': 'Retrageți jetoanele mizate',
  'STAKED BALANCE': 'Staking Balance',
  'YOUR STAKEABLE': 'Sold disponibil pentru miza',
  'TOTAL STAKED': 'miza totală',
  'ESTIMATED REWARDS': 'recompensa asteptata',
  'Rewards rate is dynamic': 'Raportul de recompense este dinamic',
  Monthly: 'lunar',
  Daily: 'privire',
  'CURRENT REWARDS': 'recompensa actuală',
  'TOTAL REWARDS': 'recompensă totală',
  'CLAIM REWARDS': 'cerere de despăgubire',
  'TOTAL SUPPLY': 'oferta totala',
  'OUR PARTNERS and BACKERS': 'Partenerii și sponsorii noștri',
  'ELONXCAT TEAM': 'Echipa ELONXCAT',
  Founder: 'fondator',
  'Creative Director': 'director de creație',
  'Community manger': 'community manager',
  'CONNECT YOUR WALLET': 'Conexiune la portofel',
  'ENTER PRESALE': 'Participarea înainte de vânzare',
  'CLAIM ELONXCAT': 'Facturare ELONXCAT',
  'ROAD MAP': 'foaie de parcurs',
  DEVELOPMENT: 'dezvoltare',
  'MEME DOMINATION': 'Donație Meme',
  'FREQUENTLY ASKED QUESTIONS': 'Întrebări frecvente',
  'What is a presale': 'Ce este o pre-vânzare?',
  'What is ELONXCAT': 'Ce este ELONXCAT?',
  'How do I participate in the presale': 'Cum particip la pre-vânzare?',
  'When do I receive my presale tokens':
    'Când voi primi jetoanele mele de pre-vânzare?',
  'What is ELONXCAT staking': 'Ce este miza ELONXCAT?',
  'How do I stake my tokens': 'Cum îmi miz jetoanele?',
  'I staked my tokens during presale':
    'Am mizat jetoane în timpul pre-vânzării, când le pot revendica?',
  'STAY CONNECTED': 'rămâne conectat',
  'PRIVACY POLICY': 'Politica de confidențialitate',
  'HOW TO BUY': 'Cum se cumpără',
  'TERMS and CONDITIONS': 'Termeni de utilizare',
  'PRESALE ALLOCATION': 'Alocarea înainte de vânzare',
  AIRDROP: 'airdrop',
  PROJECTFUNDS: 'finanţarea proiectelor',
  LIQUIDITY: 'lichiditate',
  MARKETING: 'marketing',
  EXCHANGES: 'schimb',
  'ECOSYSTEM FUNDS': 'fonduri ecosistemice',
  EXPANSION: 'expansiune',
  'HELP CENTER': 'Centru de ajutor',
  "If it doesn't work well on mobile": 'Dacă nu funcționează bine pe mobil',
  'please try using PC': 'vă rugăm să încercați să folosiți un PC',
  'twitter-announce1': 'Puteți verifica anunțurile în timp real pe X (Twitter)',
  'twitter-announce2': 'Nu puteți vedea postarea completă decât dacă urmăriți.',
  'twitter-announce3': 'Asigurați-vă că urmăriți!',

  texts: {
    txt_1:
      'Propria noastră tehnologie numită X acceptă acest multilanț. X acceptă funcționalitatea de migrare a lanțului, permițând deținătorilor de ELONXCAT să se deplaseze liber prin lanțurile de criptomonede de top.',
    txt_2:
      'ELONXCAT este un token meme construit pe o rețea cu mai multe lanțuri care se întinde pe lanțul BNB, Ethereum, Polygon, Solana și Tron.',
    txt_3:
      'ELONXCAT se străduiește să unească comunitatea meme de criptomonede și să devină un pionier în utilitățile multi-lanț.',
    txt_4:
      'Scopul nostru este ca numele nostru să strălucească pe fiecare DEX, pe fiecare CEX și peste tot.',
    txt_5: 'Folosind',
    txt_6: 'X va continua să extindă aceste mai multe lanțuri în viitor.',
  },

  'PRESALE TIERS': 'etapa de pre-vânzare',
  tiers: {
    tier_txt_1:
      'Prețul inițial de pre-vânzare este de 0,0000009 USD pe scaun, care se va schimba la Tier2 după 20 de zile.',
    tier_txt_2:
      'Prețul acestui nivel este stabilit la 0,000001 USD de la pre-vânzare, care se va schimba la Tier3 după 20 de zile.',
    tier_txt_3:
      'Prețul acestui nivel este stabilit la 0,000005 USD de la pre-vânzare, care se va schimba la prețul final după 20 de zile.',
    tier_txt_4:
      'Prețul de pre-vânzare al acestui nivel este de 0,000009 USD, care se va încheia cu finalizarea pre-vânzării ELONXCAT la acest preț.',
    tier_txt_5:
      'ELONXCAT își propune să fie cel mai bun DEX pe BSC, cu caracteristici precum schimburi de jetoane, pool-uri de lichiditate și cultivarea randamentului.',
    tier_txt_6:
      'ELONXCAT Quest combină jocurile și criptomoneda, oferindu-vă posibilitatea de a câștiga jetoane Lope prin misiuni și puzzle-uri în lumea blockchain.',
    tier_txt_7:
      'Proiectul ELONXCAT NFT combină arta digitală cu memecoins ELONXCAT pentru a oferi obiecte de colecție unice, bogate în utilitate, pentru a spori implicarea comunității.',
    tier_txt_8:
      'ELONXCAT combină finanțele și creativitatea într-o platformă ușor de utilizat prin integrarea AI pentru chat, sfaturi de tranzacționare, creare de meme și multe altele.',
    tier_txt_9:
      'ELONXCAT GET TOKEN GAME este un joc blockchain. Ecosistemul ELONXCAT integrează jocurile cu tokenomics, permițând jucătorilor să finalizeze diverse misiuni și să participe social pentru a câștiga tokenuri. Scopul acestui joc este nu doar să demonstreze tehnologia noastră blockchain, ci și să vă ajute să vă familiarizați cu proiectul nostru, câștigând bani prin participarea activă în joc.',
    tier_txt_10:
      'ELONXCAT Coin NFT Marketplace are o colecție unică cu caracteristici speciale și unice.',
    tier_txt_11:
      'ELONXCAT Coin NFT vor fi listate împreună cu proiectul pe toate piețele globale, precum și pe piața proprie a proiectului.',
    tier_txt_12:
      'Piața include un program mare de airdrop care va fi activat în timpul pre-vânzării cutiei NFT și a jetonului.',
    tier_txt_13:
      'Creați bloguri optimizate pentru SEO, e-mailuri de vânzări și multe altele... Economisiți timp și obțineți rezultate excelente. În cele din urmă, există un instrument de creație cu adevărat utilizabil.',
    tier_txt_14:
      'Vizualizează ceea ce visezi. Creați imagini cu text. Creați instantaneu imagini uimitoare pentru inovația dvs. artistică cu generatorul nostru de imagini AI.',
    tier_txt_15:
      'Pur și simplu selectați-vă asistentul și discutați cu un chatbot prietenos pentru a genera idei, pentru a vă îmbunătăți conținutul și, uneori, chiar pentru a râde.',
    tier_txt_16:
      'Ești gata să scrii cod la viteza fulgerului? Codați ușor cu AI Code Generator: oferă soluții instantanee pentru nevoile dvs. de programare.',
    tier_txt_17:
      'ElonXCat Swap își propune să fie o platformă de schimb descentralizată (DEX) de top pe Binance Smart Chain (BSC).',
    tier_txt_18:
      'Personalizat pentru moneda meme ElonXCat și ecosistemul său, ElonXCat Swap facilitează schimburile fluide de tokenuri, oferă fonduri de lichiditate și introduce funcții inovatoare precum yield farming și staking. Aceste funcționalități au scopul de a îmbunătăți lichiditatea, asigura comerț echitabil și contribui la creșterea generală a comunității ElonXCat.',
    tier_txt_19:
      'Distribuția recompenselor de token ELONXCAT se va face pe parcursul a doi ani la rata de 708,72 $ELONXCAT pe bloc Ethereum și poate fi revendicată odată ce revendicarea este activată.',
    tier_txt_20:
      'Conectați-vă portofelul în câteva secunde folosind Metamask sau Trust Wallet.',
    tier_txt_21:
      'Decideți cât de mult $ELONXCAT doriți să cumpărați.   Puteți achiziționa cu USDT împreună cu BNB, ETH și USDT.',
    tier_txt_22:
      'Odată ce pre-vânzarea se încheie, vă puteți revendica jetoanele $ELONXCAT prin același portofel și lanț pe care le-ați cumpărat.',
    tier_txt_23: 'Nașterea lui ELONMUSK &',
    tier_txt_24:
      'Construirea comunității  Generare de jetoane  Canale sociale  Naștere',
    tier_txt_25: 'KYC și audit  Marketing Pump  Începe prevânzarea',
    tier_txt_26:
      'Începeți marketingul  CoinMarketCap   Aplicați  Aplicați pentru CoinGecko  Actualizare ElonXCAT NFT  Dezvoltare joc ElonXCAT  Actualizare pentru miza',
    tier_txt_27:
      'Prima listă DEX   Actualizare DEXTools   Actualizare ElonXCAT AI  Ardere automată',
    tier_txt_28:
      'Marketing în curs  Stimulente comunitare  Parteneriate cu alte platforme  Numărul țintă de deținători: 1 milion+',
    tier_txt_29:
      'Prevânzările oferă investitorilor o oportunitate unică de a cumpăra noi jetoane sau criptomonede înainte de lansarea lor oficială. Această fază de acces timpuriu ne permite să valorificăm potențialul proiectului încă de la început.',
    tier_txt_30:
      'ELONXCAT este o monedă meme cu mai multe lanțuri cu adevărat avansată și este de fapt ELONMUSK & Ecosistem Web3 avansat: Staking | DEX | AI | jocuri | piata NFT.',
    tier_txt_31:
      'Puteți participa la pre-vânzare pe lanțul BNB. Conectați Best Wallet sau alt portofel la widgetul de pre-vânzare de mai sus și rezervați jetoane $ELONXCAT cu BNB, ETH sau USDT.',
    tier_txt_32:
      'Odată ce achiziția de pre-vânzare este finalizată, puteți utiliza același portofel pentru a vă revendica jetoanele înainte de prima listă DEX. Datele de facturare și listare vor fi anunțate pe conturile noastre de socializare.',
    tier_txt_33:
      'Staking-ul este acțiunea de a trimite jetoane către un contract inteligent pentru a le bloca pentru o anumită perioadă de timp. Acest lucru vă permite să câștigați mai multe jetoane $ELONXCAT de-a lungul timpului la o rată dinamică. Staking-ul ELONXCAT va fi disponibil în curând pe rețeaua BNB. Pentru mai multe informații, faceți clic pe „Staking” din bara de navigare.',
    tier_txt_34:
      'Pentru a miza $ELONXCAT, vizitați aici: https://ELONXCAT/en/staking Miza este posibilă numai cu $ELONXCAT achiziționat pe BNB. Veți avea nevoie de $ BNB în portofel pentru a procesa revendicările de recompensă de de-staking și staking.',
    tier_txt_35:
      'Dacă ați mizat $ELONXCAT, trebuie să așteptați o perioadă de maturizare de cel puțin 14 zile înainte de a-l revendica. Miza este posibilă numai cu $BNB achiziționați pe Ethereum. Veți avea nevoie de $ BNB în portofel pentru a procesa revendicările de recompensă de de-staking și staking.',
    tier_txt_36:
      'Staking-ul este acțiunea de a trimite jetoane către un contract inteligent pentru a le bloca pentru o anumită perioadă de timp. Acest lucru vă permite să câștigați mai multe jetoane $ELONXCAT de-a lungul timpului la o rată dinamică. Staking-ul ELONXCAT va fi disponibil în curând pe rețeaua BNB. Pentru mai multe informații, faceți clic pe „Staking” din bara de navigare.',
    tier_txt_37:
      'Pentru a miza $ELONXCAT, vizitați aici: https://ELONXCAT/en/staking Miza este posibilă numai cu $ELONXCAT achiziționat pe BNB. Veți avea nevoie de $ BNB în portofel pentru a procesa revendicările de recompensă de de-staking și staking.',
    tier_txt_38:
      'Dacă ați mizat $ELONXCAT, trebuie să așteptați o perioadă de maturizare de cel puțin 14 zile înainte de a-l revendica. Miza este posibilă numai cu $BNB achiziționați pe Ethereum. Veți avea nevoie de $ BNB în portofel pentru a procesa revendicările de recompensă de de-staking și staking.',
    tier_txt_39:
      'ELONXCAT OFFICE PANAMA: Etajul 43, Oceania Business Plaza, Tower 2000, Punta Colon Street, Urbanisación Punta  Pacifica, Panama City, Panama',
    tier_txt_40:
      'Disclaimer: ELONXCAT este o monedă meme, valoarea sa se bazează exclusiv pe capitalul cultural universal al memelor ELONXCAT.  Valoarea unei criptomonede poate crește sau scade. Investește cu atenție.',
  },

  TOKENBURNINGPLAN: 'PLANO DE QUEIMA DE TOKEN',
  OFFICIALELONXCATVIDEO: 'VÍDEO OFICIAL DO ELONXCAT',
  steps: {
    step_title_1: 'Deposite cripto na sua carteira',
    step_title_2: 'Selecione a rede',
    step_title_3: 'Conecte a carteira',
    step_title_4: 'Compre tokens',
    step_title_5: 'Verifique o token EXC$ na sua carteira',
    step_title_6: 'Aproveite sua jornada cripto com o ELONXCAT!',
    step_sub_title_1:
      'Por favor, deposite BNB ou USDT na sua carteira de criptomoeda (por exemplo, MetaMask).',
    step_sub_title_2:
      'Certifique-se de mudar a rede blockchain da sua carteira para BSC.',
    step_sub_title_3:
      'Encontre o botão ‘conectar carteira no widget de pré-venda’ e conecte sua carteira preferida.',
    step_sub_title_4:
      'Digite a quantidade de BNB ou USDT que você deseja trocar por $EXC e confirme a transação na sua carteira.',
    step_sub_title_5:
      'Se você não conseguir ver EXC na sua carteira, por favor, adicione o token. O endereço do contrato do token é 0xA4b3445A58111ABD407C34402aB59B0FE05bFF5a',
    step_sub_title_6:
      'A equipe ELONXCAT desenvolverá o ELONXCAT no melhor projeto de meme coin. Vamos aproveitar a jornada cripto juntos com nossa equipe!',
    how_to_buy_txt_1: "O vídeo 'COMO COMPRAR $EXC' está pronto.",
    how_to_buy_txt_2:
      "Clique no botão para assistir ao 'COMO COMPRAR' em vários idiomas!",
  },
  tokens_burning_plan_description1: 'Após a listagem na exchange,',
  tokens_burning_plan_description2:
    'Nossa equipe queimará gradualmente mais de 90% dos tokens.',
  tokens_burning_plan_description3:
    'O suprimento total dos tokens continuará a diminuir, e o preço aumentará.',
  tokens_burning_plan_description4:
    'O suprimento total dos tokens continuará a diminuir, e o preço aumentará.',
};
